import { useState, useMemo } from "preact/hooks"
import { Link, useMatch, useLocation } from "react-router-dom"
import Button from "antd/es/button"
import Menu from "antd/es/menu"
import {
  UserOutlined,
  TeamOutlined,
  LinkOutlined,
  FileOutlined,
  TagOutlined,
  TagsOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  AppstoreOutlined,
} from "@ant-design/icons"
import { optionTypes } from "./options"

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label: children ? label : <Link to={key}>{label}</Link>,
  }
}

const optionMenuItems = optionTypes.map(({ kind, name }) => {
  return getItem(name, "/admin/options/" + kind, <TagOutlined />)
})

const menuItems = [
  getItem("User View", "/", <AppstoreOutlined />),
  getItem("Experts", "/admin/experts", <UserOutlined />),
  getItem("Users", "/admin/users", <TeamOutlined />),
  getItem("Links", "/admin/links", <LinkOutlined />),
  getItem("Files", "/admin/files", <FileOutlined />),
  getItem("Options", "/admin/options", <TagsOutlined />, optionMenuItems),
]

function* flattenMenu(items) {
  for (let item of menuItems) {
    yield item
    if (item.children) {
      yield* item.children
    }
  }
}

export default function AdminMenu() {
  const [collapsed, setCollapsed] = useState(false)
  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  let location = useLocation()
  const selectedKeys = useMemo(() => {
    const selected = new Set()
    for (let item of flattenMenu(menuItems)) {
      if (item.key == "/") {
        continue
      }
      try {
        const match = useMatch({ path: item.key, end: false })
        if (match) {
          selected.add(item.key)
        }
      } catch (err) {}
    }
    return Array.from(selected)
  }, [location])

  return (
    <div className="admin-menu">
      <Menu selectedKeys={selectedKeys} mode="inline" inlineCollapsed={collapsed} items={menuItems} />
    </div>
  )
}
