import { render } from "preact"
import { App, appLoader, AdminLayout } from "./app"
import { createBrowserRouter, RouterProvider, Navigate, Outlet, useLoaderData } from "react-router-dom"
import ErrorPage from "./pages/error"
import { ExpertTable, UserTable, LinkTable, FileTable } from "./components/admin-table"
import AdminItem from "./components/admin-item"
import ExpertItem, { ExpertAdd, ExpertInfo, expertLoader } from "./components/expert-form"
import ExpertImport from "./components/expert-import"
import UserItem from "./components/user-form"
import { optionRoutes } from "./components/options"
import ExpertsPage from "./pages/experts"
import ExpertFiles from "./components/files"
import ExpertLinks from "./components/links"
import ExpertNotes from "./components/expert-notes"
import Thumbs from "./components/thumbs"

import "./scss/fonts.css"
import "./scss/main.scss"

function ExpertOutlet() {
  const expert = useLoaderData()
  return <Outlet context={expert} />
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    loader: appLoader,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <ExpertsPage />,
      },
      {
        path: "stars",
        action: Thumbs.action,
      },
      {
        path: "admin",
        element: <AdminLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/admin/experts" replace />,
          },
          {
            path: "experts",
            children: [
              {
                index: true,
                element: <ExpertTable />,
              },
              {
                path: "import",
                element: <ExpertImport />,
                action: ExpertImport.action,
              },
              {
                path: "export",
                element: <AdminItem />,
              },
              {
                path: "add",
                element: <ExpertAdd />,
              },
              {
                id: "expert",
                path: ":id",
                loader: expertLoader,
                element: <ExpertOutlet />,
                children: [
                  {
                    path: "info",
                    element: <ExpertInfo />,
                  },
                  {
                    id: "links",
                    path: "links",
                    element: <ExpertLinks />,
                    loader: ExpertLinks.loader,
                    action: ExpertLinks.action,
                  },
                  {
                    id: "files",
                    path: "files",
                    element: <ExpertFiles />,
                    loader: ExpertFiles.loader,
                    action: ExpertFiles.action,
                  },
                  {
                    id: "notes",
                    path: "notes",
                    element: <ExpertNotes />,
                    loader: ExpertNotes.loader,
                    action: ExpertNotes.action,
                  },
                ],
              },
            ],
          },
          {
            path: "users",
            children: [
              {
                index: true,
                element: <UserTable />,
              },
              {
                path: ":id",
                element: <UserItem />,
              },
            ],
          },
          {
            path: "links",
            children: [
              {
                index: true,
                element: <LinkTable />,
              },
            ],
          },
          {
            path: "files",
            children: [
              {
                index: true,
                element: <FileTable />,
              },
            ],
          },
          {
            path: "options",
            children: [
              {
                index: true,
                element: <Navigate to="/admin/options/area" replace />,
              },
              ...optionRoutes,
            ],
          },
        ],
      },
    ],
  },
])

render(<RouterProvider router={router} />, document.getElementById("app"))
