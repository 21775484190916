import { useState } from "preact/hooks"
import { useSubmit, useOutletContext, useLoaderData, useNavigate, Form } from "react-router-dom"
import Input from "antd/es/input"
import Space from "antd/es/space"
import Button from "antd/es/button"
import Empty from "antd/es/empty"
import { ExpertCard } from "./expert-form"
import { gql } from "@apollo/client"
import client from "../client"

const gGet = gql`
  query NoteGet($id: UUID!) {
    noteGet(expertID: $id) {
      expertID
      userID
      msg
      updated
      user {
        subject
      }
    }
  }
`
const gPut = gql`
  mutation NotePut($arg: NotePutParams!) {
    notePut(arg: $arg)
  }
`

const ExpertNotes = () => {
  const expert = useOutletContext() || {}
  const note = useLoaderData() || {}

  const [changed, setChanged] = useState(false)

  const controls = (
    <Button htmlType="submit" name="verb" value="put" disabled={!changed}>
      Save
    </Button>
  )
  let lastUpdate = <></>
  if (note && note.user) {
    lastUpdate = (
      <div>
        <small>
          Last updated by <strong>{note.user.subject}</strong> on <strong>{note.updated}</strong>
        </small>
      </div>
    )
  }

  return (
    <Form method="put">
      <ExpertCard activeTab="notes" expert={expert} controls={controls}>
        <input type="hidden" name="expertID" value={expert.id} />
        <Input.TextArea
          name="msg"
          defaultValue={note.msg}
          onChange={(value) => setChanged(value !== note.msg)}
          autoSize={{
            minRows: 10,
          }}
        />
        {lastUpdate}
      </ExpertCard>
    </Form>
  )
}

ExpertNotes.loader = async ({ request, params }) => {
  const { data } = await client.query({ query: gGet, fetchPolicy: "network-only", variables: { id: params.id } })
  return data?.noteGet || {}
}

ExpertNotes.action = async ({ request, params }) => {
  let data = Object.fromEntries(await request.formData())
  const arg = {
    expertID: data.expertID,
    msg: data.msg,
  }
  const { errors } = await client.mutate({ mutation: gPut, variables: { arg } })
  if (errors) {
    console.error(errors)
    throw new Response(JSON.stringify(errors, "", "   "), { status: 500 })
  }
  return null
}

export default ExpertNotes
