import { useNavigate } from "react-router-dom"
import Button from "antd/es/button"
import Input from "antd/es/input"
import { useMediaQuery } from "react-responsive"
import { RightOutlined, MailOutlined } from "@ant-design/icons"
import Tag from "./tag"
import Select from "./select"
import { FileLink } from "./file-link"

const RO = {
  className: "readOnly",
  open: false,
  onChange: false,
  allowClear: false,
}

function fullName(item, defv = "") {
  if (!item) {
    return defv
  }
  return [(item.surname || "").toUpperCase(), item.givenNames].filter((d) => d).join(", ")
}

function fullEmail(item) {
  if (!item || !item.email) {
    return "#"
  }
  return `mailto:` + encodeURIComponent(`${fullName(item)} <${item.email}>`)
}

function ExpertMobileFields({ ex, readOnly }) {
  const navigate = useNavigate()

  return (
    <div className="grid-form">
      <div class="field c6">
        <label>Services</label>
        <Select.Areas value={ex.areas} readOnly={readOnly} />
      </div>
      <div class="field c6">
        <label>Languages</label>
        <Select.Languages value={ex.languages} readOnly={readOnly} />
      </div>
      <div class="field c9">
        <label>Email</label>
        <Input.Group compact>
          <Input readOnly={readOnly} value={ex.email} />
          <Button
            icon={
              <a href={fullEmail(ex)} rel="noopener noreferrer" target="_blank">
                <MailOutlined />
              </a>
            }
          />
        </Input.Group>
      </div>
      <div class="field c3">
        <label>Status</label>
        <Select.Status value={ex.status} readOnly={readOnly} />
      </div>
      <div class="field c3">
        <label>Gender</label>
        <Select.Gender value={ex.gender} readOnly={readOnly} />
      </div>
      <div class="field c3">
        <label>Residence</label>
        <span className="input">
          <Tag.Flag value={ex.residence} />
          <Tag.Country value={ex.residence} />
        </span>
      </div>
      <div class="field c3">
        <label>Nationality</label>
        <span className="input">
          <Tag.Flag value={ex.nationality} />
          <Tag.Country value={ex.nationality} />
        </span>
      </div>
      <div class="field c9">
        <label>Institution</label>
        <Input readOnly={readOnly} value={ex.institution} />
      </div>
      <div class="field c8">
        <label>Company</label>
        <Input readOnly={readOnly} value={ex.companyName} />
      </div>
      <div class="field c8">
        <label>Website</label>
        <Input.Group compact>
          <Input readOnly={readOnly} value={ex.website} />
          <Button
            icon={
              <a href={ex.website} rel="noopener noreferrer" target="_blank">
                <RightOutlined />
              </a>
            }
          />
        </Input.Group>
      </div>
      <div class="field c4 r2">
        <label>Address</label>
        <Input.TextArea readOnly={readOnly} value={ex.address} />
      </div>
      <div class="field c3">
        <label>Created Date</label>
        <Input readOnly={readOnly} value={ex.createdDate} />
      </div>
      <div class="field c3">
        <label>Refresh Date</label>
        <Input readOnly={readOnly} value={ex.refreshDate} />
      </div>
      {!!ex?.files?.length ? (
        <div class="field c4">
          <label>Files</label>
          <ol>
            {(ex.files || []).map((file) => (
              <li key={file.id}>
                <FileLink expert={ex} file={file} edit={false} />
              </li>
            ))}
          </ol>
        </div>
      ) : (
        <></>
      )}
      {!!ex?.links?.length ? (
        <div class="field c8">
          <label>Links</label>
          <ol>
            {(ex.links || []).map((link) => (
              <li key={link.id}>
                <a href={link.url} rel="noopener noreferrer" target="_blank">
                  {link.url}
                </a>
              </li>
            ))}
          </ol>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default function ExpertFields({ ex, readOnly }) {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  })

  if (!ex) {
    return <></>
  }
  if (isMobile) {
    return ExpertMobileFields({ ex, readOnly })
  }

  return (
    <div className="grid-form">
      <div class="field c6">
        <label>Services</label>
        <Select.Areas value={ex.areas} readOnly={readOnly} />
      </div>
      <div class="field c6">
        <label>Languages</label>
        <Select.Languages value={ex.languages} readOnly={readOnly} />
      </div>

      <div class="field c3">
        <label>Residence</label>
        <span className="input">
          <Tag.Flag value={ex.residence} />
          <Tag.Country value={ex.residence} />
        </span>
      </div>
      <div class="field c9">
        <label>Email</label>
        <a href={fullEmail(ex)} rel="noopener noreferrer" target="_blank">
          {ex.email || ""}&nbsp;
        </a>
      </div>
      <div class="field c3">
        <label>Nationality</label>
        <span className="input">
          <Tag.Flag value={ex.nationality} />
          <Tag.Country value={ex.nationality} />
        </span>
      </div>
      <div class="field c9">
        <label>Institution</label>
        <Input readOnly={readOnly} value={ex.institution} />
      </div>
      <div class="field c3">
        <label>Status</label>
        <Select.Status value={ex.status} readOnly={readOnly} />
      </div>
      <div class="field c3">
        <label>Gender</label>
        <Select.Gender value={ex.gender} readOnly={readOnly} />
      </div>
      <div class="field c3">
        <label>Created Date</label>
        <Input readOnly={readOnly} value={ex.createdDate} />
      </div>
      <div class="field c3">
        <label>Refresh Date</label>
        <Input readOnly={readOnly} value={ex.refreshDate} />
      </div>
      <div class="field c4 r2">
        <label>Address</label>
        <Input.TextArea readOnly={readOnly} value={ex.address} />
      </div>
      <div class="field c8">
        <label>Company</label>
        <Input readOnly={readOnly} value={ex.companyName} />
      </div>
      <div class="field c8">
        <label>Website</label>
        <a href={ex.website || "/"} rel="noopener noreferrer" target="_blank">
          {ex.website}&nbsp;
        </a>
      </div>
      {!!ex?.files?.length ? (
        <div class="field c4">
          <label>Files</label>
          <ol>
            {(ex.files || []).map((file) => (
              <li key={file.id}>
                <FileLink expert={ex} file={file} edit={false} />
              </li>
            ))}
          </ol>
        </div>
      ) : (
        <></>
      )}
      {!!ex?.links?.length ? (
        <div class="field c8">
          <label>Links</label>
          <ol>
            {(ex.links || []).map((link) => (
              <li key={link.id}>
                <a href={link.url} rel="noopener noreferrer" target="_blank">
                  {link.url}
                </a>
              </li>
            ))}
          </ol>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export function ExpertFormFields({ ex, readOnly }) {
  if (!ex) {
    return <></>
  }

  return (
    <div className="grid-form">
      <div class="field">
        <label>Title</label>
        <Input readOnly={readOnly} value={ex.title} />
      </div>
      <div class="field c5">
        <label>Given Names</label>
        <Input readOnly={readOnly} value={ex.givenNames} />
      </div>
      <div class="field c4">
        <label>Surname</label>
        <Input readOnly={readOnly} value={ex.surname} />
      </div>
      <div class="field c2">
        <label>Status</label>
        <Select.Status value={ex.status} readOnly={readOnly} />
      </div>
      <div class="field c6">
        <label>Services</label>
        <Select.Areas value={ex.areas} readOnly={readOnly} />
      </div>
      <div class="field c6">
        <label>Languages</label>
        <Select.Languages value={ex.languages} readOnly={readOnly} />
      </div>
      <div class="field c3">
        <label>Gender</label>
        <Select.Gender value={ex.gender} readOnly={readOnly} />
      </div>
      <div class="field c3">
        <label>Date of Birth</label>
        <Input readOnly={readOnly} value={ex.dateOfBirth} />
      </div>
      <div class="field c3">
        <label>Created Date</label>
        <Input readOnly={readOnly} value={ex.createdDate} />
      </div>
      <div class="field c3">
        <label>Refresh Date</label>
        <Input readOnly={readOnly} value={ex.refreshDate} />
      </div>
      <div class="field c3">
        <label>Residence</label>
        <span className="input">
          <Tag.Flag value={ex.residence} />
          <Tag.Country value={ex.residence} />
        </span>
      </div>
      <div class="field c9">
        <label>Email</label>
        <a href={fullEmail(ex)} rel="noopener noreferrer" target="_blank">
          {ex.email}
        </a>
      </div>
      <div class="field c3">
        <label>Nationality</label>
        <span className="input">
          <Tag.Flag value={ex.nationality} />
          <Tag.Country value={ex.nationality} />
        </span>
      </div>
      <div class="field c9">
        <label>Institution</label>
        <Input readOnly={readOnly} value={ex.institution} />
      </div>
      <div class="field c4 r2">
        <label>Address</label>
        <Input.TextArea readOnly={readOnly} value={ex.address} />
      </div>
      <div class="field c8">
        <label>Company</label>
        <Input readOnly={readOnly} value={ex.companyName} />
      </div>
      <div class="field c8">
        <label>Website</label>
        <a href={ex.website} rel="noopener noreferrer" target="_blank">
          {ex.website}
        </a>
      </div>
      <div class="field c4">
        <label>Files</label>
        <ol>
          {(ex.files || []).map((file) => (
            <li key={file.id}>
              <FileLink expert={ex} file={file} edit={false} />
            </li>
          ))}
        </ol>
      </div>
      <div class="field c8">
        <label>Links</label>
        <ol>
          {(ex.links || []).map((link) => (
            <li key={link.id}>{link.url}</li>
          ))}
        </ol>
      </div>
    </div>
  )
}
