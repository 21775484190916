import { useReducer, useRef, useState } from "preact/hooks"
import SearchInput from "../components/search-input"
import { useQuery, gql } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import AntSelect from "antd/es/select"
import Button from "antd/es/button"
import { CloseOutlined, SettingOutlined } from "@ant-design/icons"
import Segmented from "antd/es/segmented"
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons"
import Select from "../components/select"
import ExpertGrid from "../components/expert-grid"
import { useUser } from "../contexts"
import roseLogo from "/rose.svg"

const STATE_KEY = "rose-expert-filter2"

const initialState = {
  query: "",
  areas: [],
  languages: [],
  locations: [],
  sort: "",
}

const GET_EXPERTS = gql`
  query ($arg: ExpertSearchParams!) {
    expertSearch(arg: $arg) {
      id
      createdDate
      refreshDate
      title
      givenNames
      surname
      email
      address
      institution
      nationality
      residence
      gender
      dateOfBirth
      status
      companyName
      website
      starred
      pinned
      netRating
      numRatings
      areas
      languages
      files {
        id
        fileType
        filename
        mimetype
        size
        updated
        created
      }
      links {
        id
        url
        updated
      }
      updated
      created
    }
  }
`

const sortOptions = [
  { value: "", label: "Sort Randomly" },
  { value: "name", label: "By Name" },
  // {value: "email", label: "By Email"},
  { value: "pinned", label: "By Favourites" },
  { value: "starred", label: "By Most Recommended" },
  { value: "updated", label: "Last Updated" },
]

export const DisplaySelector = ({ display, setDisplay }) => {
  return (
    <Segmented
      value={display}
      onChange={setDisplay}
      size="small"
      options={[
        {
          // label: "Cards",
          value: "cards",
          icon: <AppstoreOutlined />,
        },
        {
          // label: "List",
          value: "list",
          icon: <BarsOutlined />,
        },
      ]}
    />
  )
}

export default function ExpertsPage() {
  const navigate = useNavigate()
  const user = useUser()
  const [display, setDisplay] = useState("cards")

  const loadState = () => {
    const state = JSON.parse(window.localStorage.getItem(STATE_KEY))
    return state || initialState
  }

  const { loading, error, data, refetch } = useQuery(GET_EXPERTS, {
    variables: {
      arg: loadState(),
    },
  })

  const saveState = (state) => {
    refetch({ arg: state })
    const src = JSON.stringify(state)
    window.localStorage.setItem(STATE_KEY, src)
    return state
  }

  const reducer = (state, action) => {
    switch (action?.type) {
      case "reset":
        return saveState({
          ...initialState,
        })
      case "query":
        return saveState({
          ...state,
          query: action.payload || "",
        })
      case "areas":
        return saveState({
          ...state,
          areas: action.payload || [],
        })
      case "languages":
        return saveState({
          ...state,
          languages: action.payload || [],
        })
      case "locations":
        return saveState({
          ...state,
          locations: action.payload || [],
        })
      case "sort":
        return saveState({
          ...state,
          sort: action.payload || "",
        })
      case "pin":
        return state
      default:
        return saveState(state)
    }
  }

  const [state, dispatch] = useReducer(reducer, loadState())

  const handleQuery = (event) => {
    dispatch({ type: "query", payload: event?.target?.value })
  }

  const handleSelect = (name) => {
    return (value) => {
      dispatch({ type: name, payload: value })
    }
  }

  const expertFilter = useRef(null)

  if (error) return <p>Error : {error.message}</p>

  return (
    <>
      <aside>
        <div className="expert-filter" id="expert-filter-container">
          <SearchInput value={state.query} onChange={handleQuery} />
          <Select.Areas
            value={state.areas}
            onChange={handleSelect("areas")}
            placeholder="Area(s) of Expertise"
            getPopupContainer={() => document.getElementById("expert-filter-container")}
          />
          <Select.Languages
            value={state.languages}
            onChange={handleSelect("languages")}
            placeholder="Working Language(s)"
            getPopupContainer={() => document.getElementById("expert-filter-container")}
          />
          <Select.Locations
            value={state.locations}
            onChange={handleSelect("locations")}
            placeholder="Location"
            getPopupContainer={() => document.getElementById("expert-filter-container")}
          />
          <AntSelect
            allowClear
            value={state.sort || undefined}
            onChange={handleSelect("sort")}
            placeholder="Sort Randomly"
            getPopupContainer={() => document.getElementById("expert-filter-container")}
            optionFilterProp="label"
            showArrow={true}
            options={sortOptions}
            clearIcon={<CloseOutlined />}
          />
          <div className="expert-grid-header">
            <DisplaySelector display={display} setDisplay={setDisplay} />
            <a
              className="reset-all"
              onClick={() => {
                dispatch({ type: "reset" })
              }}>
              Reset All
            </a>
          </div>
          {user.admin ? (
            <Button
              className="admin-link"
              onClick={() => {
                navigate("/admin")
              }}
              type="text"
              icon={<SettingOutlined />}>
              Admin
            </Button>
          ) : null}
        </div>
        <div className="credits">
          <div class="logo">
            <img src="/rose.svg" alt="ROSE - Roster of Service Experts" />
          </div>
          <h1>ROSE</h1>
          <div className="full-rose">Roster of Service Experts</div>
          <p>A proudly PEF Innovation</p>
          <div>
            Help:{" "}
            <a href="mailto:Process%20Efficiency%20%3Cprojects%40who.int%3E?subject=ROSE%20Help">projects@who.int</a>
          </div>
        </div>
      </aside>
      <main>
        {loading ? <></> : <ExpertGrid display={display} experts={data?.expertSearch || []} dispatch={dispatch} />}
      </main>
      <div className="confidentialOverlay">WHO Confidential</div>
    </>
  )
}
