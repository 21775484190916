import { useRouteError, useNavigate } from "react-router-dom"
import Button from "antd/es/button"
import Result from "antd/es/result"

export default function ErrorPage() {
  const navigate = useNavigate()

  const error = useRouteError()
  console.error(error)

  return (
    <div id="error-page" class="modal">
      <Result
        status="error"
        title="Sorry, an unexpected error has occurred."
        subTitle={error.statusText || error.message}
        extra={[
          <Button key="console" onClick={() => navigate(-1)}>
            Go Back
          </Button>,
        ]}></Result>
    </div>
  )
}
