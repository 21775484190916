import { useNavigate, useParams, Link } from "react-router-dom"
import { useQuery, gql } from "@apollo/client"
import { useForm, Controller } from "react-hook-form"
import Breadcrumb from "antd/es/breadcrumb"
import Button from "antd/es/button"
import Card from "antd/es/card"
import Input from "antd/es/input"
import Space from "antd/es/space"
import Switch from "antd/es/switch"
import Typography from "antd/es/typography"
import { userPut } from "../client"
const { Text } = Typography

const USER_GQL = gql`
  query ($id: UUID!) {
    userGet(id: $id) {
      id
      subject
      admin
      banned
      lastLogin
      updated
      created
    }
  }
`

function HInput({ control, name, rules }) {
  return <Controller name={name} control={control} rules={rules} render={({ field }) => <Input {...field} />} />
}

const RO = {
  className: "readOnly",
  open: false,
  onChange: false,
  allowClear: false,
}

export default function UserItem() {
  const navigate = useNavigate()

  let { id } = useParams()

  const { loading, error, data, refetch } = useQuery(USER_GQL, {
    variables: {
      id,
    },
  })

  if (loading || error || !data) {
    return <></>
  }

  const user = data.userGet

  const {
    reset,
    register,
    handleSubmit,
    watch,
    control,
    formState: { isDirty, errors, isSubmitting },
  } = useForm({
    defaultValues: user,
  })

  const onSubmit = async (data) => {
    const result = await userPut(data)
    // reset(result);
    refetch({ id: result.id })
    navigate("/admin/users")
  }

  const onError = (errors, e) => console.error(errors, e)

  const onClose = () => {
    navigate("/admin/users")
  }

  return (
    <div className="admin-item">
      <Breadcrumb
        items={[
          { title: "Admin" },
          { title: "Users", href: "/admin/users" },
          { title: <Text strong>{user.subject}</Text> },
        ]}
      />
      <Card
        title={user.subject}
        bordered={true}
        extra={
          <Space>
            <Button onClick={onClose}>Back</Button>
            <Button
              type="primary"
              loading={isSubmitting}
              onClick={handleSubmit(onSubmit, onError)}
              disabled={!isDirty}
              d>
              Save
            </Button>
          </Space>
        }>
        <div className="grid-form">
          <div class="field c2">
            <label>Admin</label>
            <Controller
              name="admin"
              control={control}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Switch
                  onBlur={onBlur} // notify when input is touched
                  onChange={onChange} // send value to hook form
                  checked={value}
                  inputRef={ref}
                />
              )}
            />
          </div>
        </div>
      </Card>
    </div>
  )
}
