import { useState } from "preact/hooks"
import { useNavigate, Link, useSubmit, redirect } from "react-router-dom"
import { gql } from "@apollo/client"
import Alert from "antd/es/alert"
import Breadcrumb from "antd/es/breadcrumb"
import Button from "antd/es/button"
import Card from "antd/es/card"
import Space from "antd/es/space"
import Table from "antd/es/table"
import Typography from "antd/es/typography"
import Upload from "antd/es/upload"
const { Text } = Typography
const { Dragger } = Upload
import excelIcon from "../assets/excel.svg"
const Excel = window.ExcelJS

import client from "../client"

const RX_DATE = /(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) [0-9]{1,2}, [0-9]{4}/i
const RX_TWO_DATE =
  /(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) [0-9]{1,2}, [0-9]{4} - (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) [0-9]{1,2}, [0-9]{4}/i

class LowerStringMap extends Map {
  has(key) {
    if (typeof key !== "string") {
      return false
    }
    return super.has(key.toLowerCase())
  }
  get(key) {
    if (typeof key !== "string") {
      return null
    }
    return super.get(key.toLowerCase())
  }
  set(key, value) {
    if (typeof key !== "string") {
      return
    }
    return super.set(key.toLowerCase(), value)
  }
}

const GENDER = new LowerStringMap([
  ["m", "M"],
  ["f", "F"],
  ["x", "X"],
  ["male", "M"],
  ["female", "F"],
  ["other", "X"],
])

const COUNTRY = new LowerStringMap([
  ["afghanistan", "AFG"],
  ["albania", "ALB"],
  ["algeria", "DZA"],
  ["andorra", "AND"],
  ["angola", "AGO"],
  ["antigua and barbuda", "ATG"],
  ["antigua", "ATG"],
  ["barbuda", "ATG"],
  ["argentina", "ARG"],
  ["armenia", "ARM"],
  ["australia", "AUS"],
  ["austria", "AUT"],
  ["azerbaijan", "AZE"],
  ["bahamas (the)", "BHS"],
  ["bahamas", "BHS"],
  ["the bahamas", "BHS"],
  ["bahrain", "BHR"],
  ["bangladesh", "BGD"],
  ["barbados", "BRB"],
  ["belarus", "BLR"],
  ["belgium", "BEL"],
  ["belize", "BLZ"],
  ["benin", "BEN"],
  ["bhutan", "BTN"],
  ["bolivia (plurinational state of)", "BOL"],
  ["bolivia", "BOL"],
  ["bosnia and herzegovina", "BIH"],
  ["bosnia", "BIH"],
  ["herzegovina", "BIH"],
  ["botswana", "BWA"],
  ["brazil", "BRA"],
  ["brunei darussalam", "BRN"],
  ["brunei", "BRN"],
  ["bulgaria", "BGR"],
  ["burkina faso", "BFA"],
  ["burundi", "BDI"],
  ["cabo verde", "CPV"],
  ["cambodia", "KHM"],
  ["cameroon", "CMR"],
  ["canada", "CAN"],
  ["central african republic", "CAF"],
  ["chad", "TCD"],
  ["chile", "CHL"],
  ["china", "CHN"],
  ["colombia", "COL"],
  ["comoros (the)", "COM"],
  ["comoros", "COM"],
  ["the comoros", "COM"],
  ["congo", "COG"],
  ["cook islands", "COK"],
  ["costa rica", "CRI"],
  ["croatia", "HRV"],
  ["cuba", "CUB"],
  ["cyprus", "CYP"],
  ["czech republic", "CZE"],
  ["côte d'ivoire", "CIV"],
  ["cote d'ivoire", "CIV"],
  ["democratic people's republic of korea", "PRK"],
  ["north korea", "PRK"],
  ["democratic republic of the congo", "COD"],
  ["the democratic republic of the congo", "COD"],
  ["congo, the democratic republic of the", "COD"],
  ["congo", "COD"],
  ["denmark", "DNK"],
  ["djibouti", "DJI"],
  ["dominica", "DMA"],
  ["dominican republic (the)", "DOM"],
  ["the dominican republic", "DOM"],
  ["dominican republic", "DOM"],
  ["ecuador", "ECU"],
  ["egypt", "EGY"],
  ["el salvador", "SLV"],
  ["equatorial guinea", "GNQ"],
  ["eritrea", "ERI"],
  ["estonia", "EST"],
  ["eswatini", "SWZ"],
  ["ethiopia", "ETH"],
  ["fiji", "FJI"],
  ["finland", "FIN"],
  ["france", "FRA"],
  ["gabon", "GAB"],
  ["gambia, the islamic republic of the", "GMB"],
  ["the gambia", "GMB"],
  ["gambia", "GMB"],
  ["georgia", "GEO"],
  ["germany", "DEU"],
  ["ghana", "GHA"],
  ["greece", "GRC"],
  ["grenada", "GRD"],
  ["guatemala", "GTM"],
  ["guinea", "GIN"],
  ["guinea-bissau", "GNB"],
  ["guyana", "GUY"],
  ["haiti", "HTI"],
  ["honduras", "HND"],
  ["hungary", "HUN"],
  ["iceland", "ISL"],
  ["india", "IND"],
  ["indonesia", "IDN"],
  ["iran (islamic republic of)", "IRN"],
  ["iran, islamic republic of", "IRN"],
  ["islamic republic of iran", "IRN"],
  ["iran", "IRN"],
  ["iraq", "IRQ"],
  ["ireland", "IRL"],
  ["israel", "ISR"],
  ["italy", "ITA"],
  ["jamaica", "JAM"],
  ["japan", "JPN"],
  ["jordan", "JOR"],
  ["kazakhstan", "KAZ"],
  ["kenya", "KEN"],
  ["kiribati", "KIR"],
  ["kuwait", "KWT"],
  ["kyrgyzstan", "KGZ"],
  ["lao people's democratic republic", "LAO"],
  ["democratic republic of laos", "LAO"],
  ["laos", "LAO"],
  ["latvia", "LVA"],
  ["lebanon", "LBN"],
  ["lesotho", "LSO"],
  ["liberia", "LBR"],
  ["libya", "LBY"],
  ["lithuania", "LTU"],
  ["luxembourg", "LUX"],
  ["madagascar", "MDG"],
  ["malawi", "MWI"],
  ["malaysia", "MYS"],
  ["maldives", "MDV"],
  ["mali", "MLI"],
  ["malta", "MLT"],
  ["marshall islands", "MHL"],
  ["mauritania", "MRT"],
  ["mauritius", "MUS"],
  ["mexico", "MEX"],
  ["micronesia (federated states of)", "FSM"],
  ["federated states of micronesia", "FSM"],
  ["micronesia", "FSM"],
  ["monaco", "MCO"],
  ["mongolia", "MNG"],
  ["montenegro", "MNE"],
  ["morocco", "MAR"],
  ["mozambique", "MOZ"],
  ["myanmar", "MMR"],
  ["namibia", "NAM"],
  ["nauru", "NRU"],
  ["nepal", "NPL"],
  ["netherlands", "NLD"],
  ["new zealand", "NZL"],
  ["nicaragua", "NIC"],
  ["niger", "NER"],
  ["nigeria", "NGA"],
  ["niue", "NIU"],
  ["north macedonia", "MKD"],
  ["norway", "NOR"],
  ["oman", "OMN"],
  ["pakistan", "PAK"],
  ["palau", "PLW"],
  ["panama", "PAN"],
  ["papua new guinea", "PNG"],
  ["paraguay", "PRY"],
  ["peru", "PER"],
  ["philippines", "PHL"],
  ["poland", "POL"],
  ["portugal", "PRT"],
  ["qatar", "QAT"],
  ["republic of korea", "KOR"],
  ["korea, republic of", "KOR"],
  ["south korea", "KOR"],
  ["republic of moldova", "MDA"],
  ["moldova", "MDA"],
  ["romania", "ROU"],
  ["russian federation", "RUS"],
  ["russia", "RUS"],
  ["rwanda", "RWA"],
  ["saint kitts and nevis", "KNA"],
  ["saint kitts", "KNA"],
  ["nevis", "KNA"],
  ["saint lucia", "LCA"],
  ["saint vincent and the grenadines", "VCT"],
  ["saint vincent", "VCT"],
  ["the grenadines", "VCT"],
  ["samoa", "WSM"],
  ["san marino", "SMR"],
  ["sao tome and principe", "STP"],
  ["saudi arabia", "SAU"],
  ["senegal", "SEN"],
  ["serbia", "SRB"],
  ["seychelles", "SYC"],
  ["sierra leone", "SLE"],
  ["singapore", "SGP"],
  ["slovakia", "SVK"],
  ["slovenia", "SVN"],
  ["solomon islands", "SLB"],
  ["somalia", "SOM"],
  ["south africa", "ZAF"],
  ["south sudan", "SSD"],
  ["spain", "ESP"],
  ["sri lanka", "LKA"],
  ["sudan", "SDN"],
  ["suriname", "SUR"],
  ["sweden", "SWE"],
  ["switzerland", "CHE"],
  ["syrian arab republic", "SYR"],
  ["syria", "SYR"],
  ["tajikistan", "TJK"],
  ["tanzania, united republic of", "TZA"],
  ["united republic of tanzania", "TZA"],
  ["tanzania", "TZA"],
  ["thailand", "THA"],
  ["timor-leste", "TLS"],
  ["togo", "TGO"],
  ["tonga", "TON"],
  ["trinidad and tobago", "TTO"],
  ["trinidad", "TTO"],
  ["tobago", "TTO"],
  ["tunisia", "TUN"],
  ["turkey", "TUR"],
  ["turkmenistan", "TKM"],
  ["tuvalu", "TUV"],
  ["uganda", "UGA"],
  ["ukraine", "UKR"],
  ["united arab emirates", "ARE"],
  ["united kingdom of great britain and northern ireland (the)", "GBR"],
  ["the united kingdom of great britain and northern ireland", "GBR"],
  ["united kingdom of great britain", "GBR"],
  ["the united kingdom of great britain", "GBR"],
  ["united kingdom", "GBR"],
  ["the united kingdom", "GBR"],
  ["northern ireland", "GBR"],
  ["united states of america", "USA"],
  ["united states", "USA"],
  ["us", "USA"],
  ["usa", "USA"],
  ["uruguay", "URY"],
  ["uzbekistan", "UZB"],
  ["vanuatu", "VUT"],
  ["venezuela (bolivarian republic of)", "VEN"],
  ["venezuela", "VEN"],
  ["viet nam", "VNM"],
  ["yemen", "YEM"],
  ["zambia", "ZMB"],
  ["zimbabwe", "ZWE"],
])

const fmtDate = (s) => {
  try {
    const t = Date.parse(s)
    if (t !== 0) {
      return new Date(t).toISOString().substr(0, 10)
    }
  } catch (err) {}
  return s
}

//##############################################################################

const snake = (s) => {
  if (typeof s !== "string") {
    return NaN
  }
  return s.toLowerCase().trim().replaceAll(/-+/g, "").replaceAll(/ +/g, "_")
}

class ExcelMap {
  constructor(worksheet, cols) {
    this.ws = worksheet
    this.indexes = new Map()
    this.headerRow = this.findHeaderRow(cols[0])
    for (let i = 1; i < cols.length; i++) {
      this.findColumn(this.headerRow, cols[i])
    }

    this.width = 0
    for (let col of this.indexes.values()) {
      this.width += col.width || 100
    }

    this.cols = Array.from(this.indexes.values())

    const self = this
    this.rows = []
    this.dataRows = []

    this.ws.eachRow(function (row, rn) {
      if (rn <= self.headerRow) {
        return
      }

      const obj = {}
      const rowObj = {}
      for (const [idx, col] of self.indexes.entries()) {
        let v = row.values[idx] || ""
        obj[col.dataIndex] = v
        const cell = { value: v }
        if (col.warning) {
          cell.error = col.warning(v)
        }
        rowObj[col.dataIndex] = cell
      }
      self.rows.push(rowObj)
      try {
        const dataObj = self.toData(obj)
        if (dataObj) {
          self.dataRows.push(dataObj)
        }
      } catch (err) {
        console.error(err)
      }
    })
  }

  findColumn(i, col) {
    const sv = snake(col.title)
    const row = this.ws.getRow(i)
    const idx = row.values.findIndex((v) => snake(v) == sv)
    if (0 <= idx) {
      this.indexes.set(idx, col)
      // this.missing.delete(value);
    }
    return idx
  }

  findHeaderRow(col) {
    for (let i = 0; i <= 21; i++) {
      if (0 <= this.findColumn(i, col)) {
        return i
      }
    }
    throw new Error(`required column "${col.title}" not found`)
  }

  toData(d) {
    if (!d.identification_number) {
      return null
    }

    const obj = {
      idNum: d.identification_number,
    }
    if (d.last_name) {
      obj.surname = d.last_name
    }
    if (d.first_name || d.middle_name) {
      obj.givenNames = [d.first_name, d.middle_name].filter((n) => n).join(" ")
    }
    if (d.gender && GENDER.has(d.gender)) {
      obj.gender = GENDER.get(d.gender)
    }
    if (d.nationality && COUNTRY.has(d.nationality)) {
      obj.nationality = COUNTRY.get(d.nationality)
    }
    if (d.date_of_birth && RX_DATE.test(d.date_of_birth)) {
      obj.dateOfBirth = fmtDate(d.date_of_birth)
    }
    if (d.submission_creation_date_and_last_update && RX_TWO_DATE.test(d.submission_creation_date_and_last_update)) {
      const [dateA, dateB] = d.submission_creation_date_and_last_update.split(" - ")
      obj.createdDate = fmtDate(dateA)
      obj.refreshDate = fmtDate(dateB)
    }
    return obj
  }
}

//##############################################################################

const renderCol = (d) => {
  if (d.error) {
    return (
      <Text type="warning">
        <span title={d.error}>{d.value}</span>
      </Text>
    )
  }
  return d.value
}

const columns = [
  {
    render: renderCol,
    title: "Identification Number",
    dataIndex: "identification_number",
    width: 160,
  },
  // {
  //   title: "Profile",
  //   dataIndex: "profile",
  // },
  {
    render: renderCol,
    title: "Last Name",
    dataIndex: "last_name",
    width: 160,
  },
  {
    render: renderCol,
    title: "First Name",
    dataIndex: "first_name",
    width: 160,
  },
  {
    render: renderCol,
    title: "Middle Name",
    dataIndex: "middle_name",
    width: 160,
  },
  {
    render: renderCol,
    title: "Gender",
    dataIndex: "gender",
    warning: (d) => {
      if (!GENDER.has(d)) {
        return "unrecognized value"
      }
    },
    width: 100,
  },
  {
    render: renderCol,
    title: "Nationality",
    dataIndex: "nationality",
    width: 280,
    warning: (d) => {
      if (!COUNTRY.has(d)) {
        return "unrecognized value"
      }
    },
  },
  {
    render: renderCol,
    title: "Date of Birth",
    dataIndex: "date_of_birth",
    width: 130,
    toView: (d) => {
      return fmtDate(d)
    },
    warning: (d) => {
      if (!RX_DATE.test(d)) {
        return "unrecognized date"
      }
    },
  },
  {
    render: renderCol,
    title: "Submission Creation Date and Last Update",
    dataIndex: "submission_creation_date_and_last_update",
    width: 340,
    warning: (d) => {
      if (!RX_TWO_DATE.test(d)) {
        return "unrecognized date"
      }
    },
  },
]

async function parseFile(file) {
  const workbook = new Excel.Workbook()
  const buffer = await file.arrayBuffer()
  await workbook.xlsx.load(buffer)
  const data = new ExcelMap(workbook.worksheets[0], columns)
  return data
}

const data = []

function ExportImportUpload({ onFileChange }) {
  const onChange = (arg) => {
    onFileChange(arg.file)
  }

  return (
    <div className="spinner">
      <Dragger
        multiple={false}
        fileList={[]}
        onChange={onChange}
        showUploadList={false}
        beforeUpload={(file) => {
          return false
        }}
        accept={".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}>
        <p className="ant-upload-drag-icon">
          <img src={excelIcon} class="excel-icon" alt="Excel icon" />
        </p>
        <p className="ant-upload-text">
          Click or drag an HR Excel file to this area to start.
          <br />
          <small>Must be Microsoft 2007 or later, ending with ".xlsx"</small>
        </p>
      </Dragger>
    </div>
  )
}

function ExpertImporter({ state, setState, runImport }) {
  const onFileChange = (file) => {
    parseFile(file)
      .then((d) => {
        setState({ err: null, data: d })
      })
      .catch((err) => {
        setState({ err: err, data: null })
      })
  }

  if (state.err) {
    return (
      <Alert
        message="Error"
        description={state.err.message}
        type="error"
        showIcon
        action={
          <Button
            onClick={() => {
              setState({ err: null, data: null })
            }}>
            Try Again
          </Button>
        }
      />
    )
  } else if (!state.data) {
    return <ExportImportUpload onFileChange={onFileChange} />
  }

  const dataRows = state.data.dataRows

  return (
    <>
      <Table
        columns={state.data.cols}
        dataSource={state.data.rows}
        size="small"
        pagination={false}
        scroll={{
          x: state.data?.width || 800,
        }}
      />
      <br />
      <Space align="end">
        <Button
          type="primary"
          onClick={() => {
            runImport(dataRows)
          }}>
          {"Import " + dataRows.length + " Experts"}
        </Button>
      </Space>
    </>
  )
}

function ExpertImport() {
  const navigate = useNavigate()
  const onClose = () => {
    navigate("/admin/experts")
  }

  const submit = useSubmit()
  const [state, setState] = useState({ err: null, data: null })

  const runImport = (x) => {
    const data = new FormData()
    data.set("src", JSON.stringify(x))
    submit(data, { method: "post" })
  }

  const controls = (
    <Space>
      <Button onClick={onClose}>Back</Button>
      <Button
        disabled={state.err === null && state.data === null}
        onClick={() => {
          setState({ err: null, data: null })
        }}>
        Reset
      </Button>
    </Space>
  )

  return (
    <div className="admin-item">
      <Breadcrumb
        items={[
          { title: "Admin" },
          { title: "Experts", href: "/admin/experts" },
          { title: <Text strong>Import</Text> },
        ]}
      />
      <Card title="Import Experts File" bordered={true} extra={controls}>
        <ExpertImporter state={state} setState={setState} runImport={runImport} />
      </Card>
    </div>
  )
}

const GQL_IMPORT = gql`
  mutation ExpertImport($arg: [ExpertImportParams!]!) {
    expertImport(arg: $arg)
  }
`

ExpertImport.action = async ({ request, params }) => {
  let data = await request.formData()
  let rows = JSON.parse(data.get("src"))
  const { errors } = await client.mutate({
    mutation: GQL_IMPORT,
    variables: {
      arg: rows,
    },
  })
  if (errors) {
    console.error(errors)
    throw new Response(JSON.stringify(errors, "", "   "), { status: 500 })
  }
  return redirect("/admin/experts")
}

export default ExpertImport
