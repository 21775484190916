import { useState } from "preact/hooks"
import { Form, useFetcher } from "react-router-dom"
import thumbsUp from "../assets/thumbs-up.svg"
import thumbsDown from "../assets/thumbs-down.svg"
import Tooltip from "antd/es/tooltip"
import Button from "antd/es/button"
import Input from "antd/es/input"
import Space from "antd/es/space"
import { gql } from "@apollo/client"
import client from "../client"

function rating(expert) {
  let n = expert.numRatings
  if (!n) {
    return null
  }

  let down = ~~Math.floor((n - expert.netRating) / 2)
  let up = n - down

  return (
    <div className="would-you-count">{`${up.toLocaleString()} out of ${n.toLocaleString()} others said they would.`}</div>
  )
}

const Thumbs = ({ expert, setChanged }) => {
  const fetcher = useFetcher()

  const [starred, setStarred] = useState(expert?.starred || 0)

  if (fetcher.formData) {
    setStarred(+fetcher.formData.get("value"))
    setChanged(true)
  }

  const downTooltip = starred == -1 ? `Undo your recommendation` : `Do not recommend this expert`
  const upTooltip = starred == 1 ? `Undo your recommendation` : `Recommend this expert`

  return (
    <>
      <div className="would-you">
        <div className="would-you-label">Would you contract this vendor again?</div>
        {rating(expert)}
      </div>
      <fetcher.Form action="/stars" method="post" className="thumb-form">
        <input type="hidden" name="expertID" value={expert?.id} />
        <Space.Compact className="thumb-space" align="center">
          <Tooltip placement="bottom" title={upTooltip} arrow={true}>
            <Button
              type={starred == 1 ? "primary" : undefined}
              htmlType="submit"
              name="value"
              value={starred == 1 ? 0 : 1}
              className="thumb-btn thumb-btn-up">
              Yes
            </Button>
          </Tooltip>
          <Tooltip placement="bottom" title={downTooltip} arrow={true}>
            <Button
              type={starred == -1 ? "primary" : undefined}
              htmlType="submit"
              name="value"
              value={starred == -1 ? 0 : -1}
              className="thumb-btn thumb-btn-down">
              No
            </Button>
          </Tooltip>
        </Space.Compact>
      </fetcher.Form>
    </>
  )
}

const gPost = gql`
  mutation ($arg: StarPostParams!) {
    starPost(arg: $arg)
  }
`

Thumbs.action = async ({ request, params }) => {
  let data = Object.fromEntries(await request.formData())
  const arg = {
    userID: "",
    expertID: data.expertID,
    value: +data.value,
  }
  const { errors } = await client.mutate({ mutation: gPost, variables: { arg } })
  if (errors) {
    console.error(errors)
    throw new Response(JSON.stringify(errors, "", "   "), { status: 500 })
  }
  return null
}
export default Thumbs
