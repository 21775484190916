import { Outlet, Navigate, useLoaderData } from "react-router-dom"
import { ApolloProvider } from "@apollo/client"
import { UserContext, LocaleContext, useUser } from "./contexts"
import ConfigProvider from "antd/es/config-provider"
import enUS from "antd/locale/en_US"
import { loadLocale } from "./locale"
import Splash from "./pages/splash"
import Header from "./components/header"
import client, { selfGet } from "./client"
import AdminMenu from "./components/admin-menu"

export function AdminLayout() {
  const user = useUser()
  if (!user.admin) {
    return <Navigate to="/" replace={true} />
  }

  return (
    <>
      <aside>
        <AdminMenu />
      </aside>
      <main>
        <Outlet />
      </main>
    </>
  )
}

export const appLoader = async ({ params }) => {
  const { user, form } = await selfGet()

  if (form) {
    return { form }
  }

  const locale = await loadLocale()
  return { user, locale }
}

export function App() {
  const { form, user, locale } = useLoaderData()

  if (!user) {
    return Splash({ form })
  }

  return (
    <ApolloProvider client={client}>
      <ConfigProvider locale={enUS}>
        <UserContext.Provider value={user}>
          <LocaleContext.Provider value={locale}>
            <Header />
            <Outlet />
          </LocaleContext.Provider>
        </UserContext.Provider>
      </ConfigProvider>
    </ApolloProvider>
  )
}
