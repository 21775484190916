import { localeGet } from "./client"

class OptionMap extends Map {
  constructor(k, ...args) {
    super(
      args
        .flat()
        .map((d) => [d.id, d[k]])
        .filter((d) => d[1])
    )
    this.value = this.value.bind(this)
    this.option = this.option.bind(this)
    this.options = this.options.bind(this)
    this.partition = this.partition.bind(this)
  }

  get(k) {
    return super.get(k) || null
  }

  value(A) {
    if (Array.isArray(A)) {
      return A.map((d) => d.value).filter((d) => d) || null
    }
    return A?.value || null
  }

  option(k) {
    if (Array.isArray(k)) {
      return k.map(this.option).filter((d) => d) || null
    }
    return this.has(k) ? { value: k, label: this.get(k) } : undefined
  }

  options(flt) {
    flt = flt || ((d) => true)
    return Array.from(this.keys())
      .filter((d) => flt(d))
      .map(this.option)
  }

  partition(labelA, labelB, selector) {
    return [
      {
        label: labelA,
        options: Array.from(this.keys())
          .filter((d) => selector(d))
          .map(this.option),
      },
      {
        label: labelB,
        options: Array.from(this.keys())
          .filter((d) => !selector(d))
          .map(this.option),
      },
    ]
  }
}

class Locale {
  KEYS = ["titles", "tags", "statuses", "genders", "areas", "languages", "fileTypes", "regions", "countries"]

  constructor(data) {
    const self = this

    this.orig = data
    this.lookup = {}
    for (let k of this.KEYS) {
      this.lookup[k] = new OptionMap("name", data[k])
    }
    this.lookup.locations = new OptionMap("name", data.regions, data.countries)

    this.lookup["countryRegions"] = new Map(
      data["countries"]
        .map((d) => {
          return [d.id, self.lookup["regions"].get(d.regionID)]
        })
        .filter((d) => d[1])
    )

    this.officials = new Set(data["languages"].filter((d) => d.official).map((d) => d.id))
  }

  filters(key) {
    return Array.from(this.lookup[key].entries()).map(([value, text]) => {
      return { text, value }
    })
  }
}

export async function loadLocale() {
  const { locale } = await localeGet()
  return new Locale(locale)
}
