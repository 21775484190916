import { useNavigate, Link } from "react-router-dom"
import { useLocale } from "../contexts"
// import {Breadcrumb, Input, Space, Table, Button} from "antd";
import Breadcrumb from "antd/es/breadcrumb"
import Button from "antd/es/button"
import Input from "antd/es/input"
import Space from "antd/es/space"
import Table from "antd/es/table"
import {
  SearchOutlined,
  CloseOutlined,
  DownloadOutlined,
  UploadOutlined,
  LoadingOutlined,
  UserAddOutlined,
} from "@ant-design/icons"
import { useSearch } from "../client"
import Tag from "./tag"
import { FileLink } from "./file-link"

const GS = { M: "Male", F: "Female", X: "Other" }
const ROW_COUNT = 10

const setParam = (params, name, value, defv = null) => {
  if (value === null || value === undefined || value === "" || value === defv) {
    params.delete(name)
  } else {
    params.set(name, value)
  }
}

const jsonEquals = (A, B) => {
  return JSON.stringify(A) === JSON.stringify(B)
}

function ExportButtons({ kind, extra }) {
  return (
    <Space.Compact>
      <Button
        icon={<DownloadOutlined />}
        onClick={() => {
          window.location.replace(`/export/${kind}.xlsx`)
        }}>
        Excel
      </Button>
      <Button
        icon={<DownloadOutlined />}
        onClick={() => {
          window.location.replace(`/export/${kind}.csv`)
        }}>
        CSV
      </Button>
      {extra}
    </Space.Compact>
  )
}

export function AdminTable({ tableName, name, extra, columns, ...props }) {
  let kind = name.toLowerCase()

  let state = {
    table: tableName,
    query: "",
    current: 1,
    pageSize: ROW_COUNT,
    sort: "updated",
    desc: true,
    filters: [],
  }

  const pagination = { current: 1, total: 0, pageSize: 15, pageSizeOptions: [15, 30, 50, 100] }
  const { loading, error, data, refetch } = useSearch(state)
  if (data && data.search) {
    pagination.current = data.search.current
    pagination.total = data.search.total
    pagination.pageSize = data.search.pageSize
  }

  const handleInputChange = (event) => {
    state = {
      ...state,
      query: event?.target?.value || "",
    }
    refetch({ arg: state })
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const fs = []
    for (let [k, v] of Object.entries(filters)) {
      if (v) {
        fs.push({ field: k, values: v })
      }
    }

    state = {
      ...state,
      current: pagination.current,
      pageSize: pagination.pageSize,
      sort: sorter.field && sorter.order ? sorter.field : "",
      desc: !!(sorter.field && sorter.order == "descend"),
      filters: fs,
    }
    refetch({ arg: state })
  }

  return (
    <div className="admin-table">
      <Breadcrumb items={[{ title: "Admin" }, { title: name }]} />
      <div className="table-search">
        <Input
          placeholder="Search"
          prefix={loading ? <LoadingOutlined spin /> : <SearchOutlined />}
          allowClear={{ clearIcon: <CloseOutlined /> }}
          onChange={handleInputChange}
          defaultValue={state.query}
        />
        <ExportButtons kind={kind} extra={extra} />
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data?.search?.items || []}
        pagination={pagination}
        loading={loading ? { indicator: <LoadingOutlined spin />, spinning: true } : false}
        onChange={handleTableChange}
        size="small"
        {...props}
      />
    </div>
  )
}

export function ExpertTable() {
  const navigate = useNavigate()
  const locale = useLocale()

  const ExpertColumns = [
    {
      title: "Name",
      dataIndex: "surname",
      sorter: true,
      render: (_, d) => {
        const name = [d?.given_names, d?.surname].filter((x) => x).join(" ")
        return <Link to={d.id + "/info"}>{name}</Link>
      },
      width: "200px",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (d) => {
        return <Tag.Status value={d} />
      },
      filters: locale.filters("statuses"),
      // render: (d) => {
      //   return statuses.get(d) || "";
      // },
      // filters: statusFilter,
    },

    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      render: (d) => {
        return <Tag.Gender value={d} />
      },
      filters: locale.filters("genders"),
    },

    // {
    //   title: "Age",
    //   dataIndex: "age",
    //   sorter: true,
    // },
    // {
    //   title: "Date of Birth",
    //   dataIndex: "date_of_birth",
    //   sorter: true,
    // },
    {
      title: "Refresh Date",
      dataIndex: "refresh_date",
      sorter: true,
      align: "right",
      render: (d) => {
        return <Tag.Date value={d} />
      },
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      sorter: true,
      align: "right",
      render: (d) => {
        return <Tag.Date value={d} />
      },
    },
  ]

  const link = (to) => {
    return () => {
      navigate(to)
    }
  }

  const extra = (
    <>
      <Button icon={<UploadOutlined />} onClick={link("/admin/experts/import")}>
        Import
      </Button>
      <Button icon={<UserAddOutlined />} onClick={link("/admin/experts/add")}>
        Add New
      </Button>
    </>
  )

  return <AdminTable tableName="expert" name="Experts" extra={extra} columns={ExpertColumns} />
}

const UserColumns = [
  {
    title: "Subject",
    dataIndex: "subject",
    render: (_, d) => {
      return <Link to={d.id}>{d.subject}</Link>
    },
    width: "200px",
  },
  {
    title: "Admin",
    dataIndex: "admin",
    render: (d) => (d ? "Yes" : "No"),
    filters: [
      {
        text: "Yes",
        value: "1",
      },
      {
        text: "No",
        value: "0",
      },
    ],
  },
  // {
  //   title: "Locked Out",
  //   dataIndex: "banned",
  //   render: (d) => (d ? "Yes" : "No"),
  //   filters: [
  //     {
  //       text: "Yes",
  //       value: "1",
  //     },
  //     {
  //       text: "No",
  //       value: "0",
  //     },
  //   ],
  // },
  {
    title: "Last Seen",
    dataIndex: "last_login",
    sorter: true,
    render: (d) => {
      return <Tag.DateTime value={d} />
    },
  },
  {
    title: "Created",
    dataIndex: "created",
    sorter: true,
    render: (d) => {
      return <Tag.DateTime value={d} />
    },
  },
]

export function UserTable() {
  const navigate = useNavigate()

  const link = (to) => {
    return () => {
      navigate(to)
    }
  }

  return <AdminTable tableName="user" name="Users" columns={UserColumns} />
}

const LinkColumns = [
  // {
  //   title: "ID",
  //   dataIndex: "id",
  // },
  {
    title: "User",
    dataIndex: "surname",
    sorter: true,
    render: (_, d) => {
      const name = [d?.given_names, d?.surname].filter((x) => x).join(" ")
      return <Link to={"../experts/" + d.expert_id + "/links"}>{name}</Link>
    },
    width: "200px",
  },
  {
    title: "URL",
    dataIndex: "url",
    sorter: true,
  },
  {
    title: "Updated",
    dataIndex: "updated",
    sorter: true,
    // defaultSortOrder: "descending",
    render: (d) => {
      return <Tag.DateTime value={d} />
    },
  },
]

export function LinkTable() {
  const navigate = useNavigate()

  const link = (to) => {
    return () => {
      navigate(to)
    }
  }

  return <AdminTable tableName="link_view" name="Links" columns={LinkColumns} />
}

export function FileTable() {
  const navigate = useNavigate()
  const locale = useLocale()

  const FileColumns = [
    {
      title: "Filename",
      dataIndex: "filename",
      sorter: true,
      render: (name, d) => {
        d.idNum = d.id_num
        d.givenNames = d.given_names
        d.fileType = d.file_type
        return <FileLink expert={d} file={d} edit={false} />
      },
    },
    {
      title: "Type",
      dataIndex: "file_type",
      render: (_, d) => {
        return <Tag.FileType value={d.file_type} />
      },
      filters: locale.filters("fileTypes"),
    },
    {
      title: "User",
      dataIndex: "surname",
      sorter: true,
      render: (_, d) => {
        const name = [d?.given_names, d?.surname].filter((x) => x).join(" ")
        return <Link to={"../experts/" + d.expert_id + "/files"}>{name}</Link>
      },
      width: "200px",
    },
    {
      title: "Size",
      dataIndex: "size",
      sorter: true,
      align: "right",
      render: (d) => {
        return <Tag.DataSize value={d} />
      },
    },
    {
      title: "Updated",
      dataIndex: "updated",
      sorter: true,
      render: (d) => {
        return <Tag.DateTime value={d} />
      },
      // defaultSortOrder: "descending",
    },
  ]

  const link = (to) => {
    return () => {
      navigate(to)
    }
  }

  return <AdminTable tableName="file_view" name="Files" columns={FileColumns} />
}
