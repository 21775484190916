import Card from "antd/es/card"
import Button from "antd/es/button"

function SplashHeader() {
  return (
    <>
      <div className="credits">
        <h1>ROSE</h1>
        <div className="full-rose">Roster of Service Experts</div>
      </div>
    </>
  )
}

export default function Splash({ form }) {
  return (
    <div class="modal">
      <Card title={null} bordered={false}>
        <p className="by-accessing">
          By accessing ROSE (Roster of Service Experts) you will have access to confidential and personal information
          about candidates. This confidential information will be provided for the exclusive purpose of identifying a
          potential vendor for contracting. You agree to treat this information as strictly confidential. No information
          may be communicated or disclosed to any individual outside of WHO and/or not involved in your contract
          preparations. Any questions about contracting procedures or confidentiality of this information should be
          directed to your responsible HR colleagues.
        </p>
        <form method="post" action={form.action} id="SAMLRequestForm">
          <input type="hidden" name="SAMLRequest" value={form.request} />
          <input type="hidden" name="RelayState" value="" />
          <Button id="SAMLSubmitButton" type="primary" htmlType="submit" block size="large" value="Submit">
            WHO Staff (WIMS) Sign-In
          </Button>
        </form>
      </Card>
    </div>
  )
}

/*
        <hr />
        <form method="post" action="/test/login">
          <input type="submit" name="subject" value="admin@example.com" />
        </form>
        <hr />
        <form method="post" action="/test/login">
          <input type="submit" name="subject" value="user@example.com" />
        </form>
        <hr />
        <form method="post" action="/test/login">
          <input type="submit" name="subject" value="bad@example.com" />
        </form>
*/
