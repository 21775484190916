import { createContext } from "preact"
import { useContext } from "preact/hooks"

export const UserContext = createContext()
export const LocaleContext = createContext({})

export function useUser() {
  return useContext(UserContext) || null
}

export function useLocale() {
  return useContext(LocaleContext) || {}
}
