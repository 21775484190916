import whoLogo from "/h-logo-white.svg"
import { Link } from "react-router-dom"

export default function Header() {
  return (
    <header>
      <h1>
        <Link to="/">
          <img src={whoLogo} class="logo rose" alt="WHO" />
        </Link>
      </h1>
    </header>
  )
}

//       <div className="confidential">Confidential</div>
