import { useMemo, useState } from "preact/hooks"
import { useLocale } from "../contexts"
import AntSelect from "antd/es/select"
import { CloseOutlined } from "@ant-design/icons"

const defaultMakeOpts = (lookup, locale) => {
  return lookup.options()
}

function sel(kind, isMulti, makeOpts) {
  makeOpts = makeOpts || defaultMakeOpts
  const mode = isMulti ? "multiple" : undefined

  return function (props) {
    if (props.readOnly) {
      // props.className = props.className + " readOnly";
      props.open = false
      props.onChange = false
      props.allowClear = false
      props.showArrow = false
      props.removeIcon = <></>
      props.showSearch = false
    }

    const locale = useLocale()
    const [open, setOpen] = useState(false)

    const options = useMemo(() => {
      const lookup = locale.lookup[kind]
      return makeOpts(lookup, locale)
    }, [locale, kind, makeOpts])

    return (
      <AntSelect
        mode={mode}
        allowClear
        open={open}
        onDropdownVisibleChange={(visible) => setOpen(visible)}
        onSelect={() => setOpen(false)}
        optionFilterProp="label"
        showArrow={true}
        options={options}
        clearIcon={<CloseOutlined />}
        showSearch
        {...props}
      />
    )
  }
}

const Select = {
  Title: sel("titles", false),
  Status: sel("statuses", false),
  Gender: sel("genders", false),
  Area: sel("areas", false),
  Language: sel("languages", false),
  FileType: sel("fileTypes", false),
  Region: sel("regions", false),
  Country: sel("countries", false),
  // CountryRegion: sel("countryRegions", false),
  // CountryShortRegion: sel("countryShortRegions", false),
  Tags: sel("tags", true),
  Areas: sel("areas", true),
  Languages: sel("languages", true, (lookup, locale) => {
    return lookup.partition("WHO Official", "Unofficial", (d) => locale.officials.has(d))
  }),
  Locations: sel("locations", true, (lookup, locale) => {
    return lookup.partition("WHO Region", "Country", (d) => locale.lookup["regions"].has(d))
  }),
}

export default Select
