import { useState } from "preact/hooks"
import { useNavigate } from "react-router-dom"
import { gql } from "@apollo/client"
import { useMediaQuery } from "react-responsive"
import Button from "antd/es/button"
import Card from "antd/es/card"
import Drawer from "antd/es/drawer"
import Empty from "antd/es/empty"
import Space from "antd/es/space"
import Typography from "antd/es/typography"
import Tooltip from "antd/es/tooltip"
import { StarFilled, PushpinFilled, PushpinOutlined } from "@ant-design/icons"
import ExpertFields from "./expert-fields"
import Tag from "./tag"
import { starPost, pinPost } from "../client"
import { useUser } from "../contexts"
const { Text } = Typography
import Thumbs from "./thumbs"
import ExpertTable from "./expert-table"

const PIN_TOOLTIP = `Add this expert to your list of favourites`

const GET_EXPERTS = gql`
  query Experts {
    experts {
      id
      createdDate
      refreshDate
      title
      givenNames
      surname
      email
      address
      institution
      nationality
      residence
      gender
      dateOfBirth
      status
      companyName
      website
      areas
      languages
      files {
        id
        filename
        mimetype
        size
        updated
        created
      }
      links {
        id
        url
        updated
      }
      updated
      created
    }
  }
`

function fullName(item, defv = "") {
  if (!item) {
    return defv
  }
  return [(item.surname || "").toUpperCase(), item.givenNames].filter((d) => d).join(", ")
}

function Pin({ expert, dispatch }) {
  const [pinned, setPinned] = useState(expert?.pinned)

  const togglePin = async () => {
    let v = !pinned
    setPinned(v)
    await pinPost(expert.id, v)
    if (dispatch) {
      dispatch()
    }
  }

  if (!expert) {
    return <></>
  }

  return (
    <Tooltip placement="bottom" title={PIN_TOOLTIP} arrow={true}>
      {pinned ? (
        <PushpinFilled
          className="pin-icon pinned"
          onClick={(event) => {
            event.stopPropagation()
            togglePin()
          }}
        />
      ) : (
        <PushpinOutlined
          className="pin-icon"
          onClick={(event) => {
            event.stopPropagation()
            togglePin()
          }}
        />
      )}
    </Tooltip>
  )
}

function CardPin({ expert, setChanged }) {
  const [pinned, setPinned] = useState(expert?.pinned)

  const togglePin = async () => {
    let v = !pinned
    setPinned(v)
    await pinPost(expert.id, v)
    setChanged(true)
  }

  if (!expert) {
    return <></>
  }

  const icon = pinned ? <PushpinFilled className="pin-icon pinned" /> : <PushpinOutlined className="pin-icon" />

  return (
    <Space.Compact className="thumb-space" align="center">
      <Tooltip placement="bottom" title={PIN_TOOLTIP} arrow={true}>
        <Button
          icon={icon}
          onClick={(event) => {
            event.stopPropagation()
            togglePin()
          }}
        />
      </Tooltip>
    </Space.Compact>
  )
}

function userRating(starred) {
  if (starred > 0) {
    return <Text type="success">&nbsp;+1&nbsp;</Text>
  }
  if (starred < 0) {
    return <Text type="danger">&nbsp;-1&nbsp;</Text>
  }
}

export function Rating({ expert }) {
  let down = ~~Math.floor((expert.numRatings - expert.netRating) / 2)
  let up = expert.numRatings - down

  if (!expert.numRatings) {
    return null
  }

  let perc = Math.round(up / expert.numRatings)

  return (
    <>
      <label>Recommended by:</label>
      <div className="gridRatings" title={perc + "%"}>
        <span>{up.toLocaleString()}</span>
        <span> of </span>
        <span>{expert.numRatings.toLocaleString()}</span>
      </div>
    </>
  )
}

function ExpertGridItem({ expert, setCurrent, dispatch }) {
  return (
    <Card
      title={
        <>
          <Tag.Flag value={expert.residence || expert.nationality} />
          <span>{fullName(expert)}</span>
        </>
      }
      bordered={false}
      onClick={() => {
        setCurrent(expert)
      }}
      extra={<Pin expert={expert} />}
      size="small">
      <div className="expert-card-grid">
        <label>Languages:</label>
        <div className="expert-card-grid-languages">
          <Tag.Languages values={expert.languages} />
        </div>
        <label>Services:</label>
        <div className="expert-card-grid-areas">
          <Tag.Areas values={expert.areas} />
        </div>
        <Rating expert={expert} />
      </div>
    </Card>
  )
}

export default function ExpertGrid({ loading, display, experts, dispatch }) {
  const navigate = useNavigate()
  const user = useUser()

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  })
  const width = isMobile ? "100vw" : "80vw"

  const [current, setCurrent] = useState(null)
  const [changed, setChanged] = useState(false)

  const editCurrent = () => {
    navigate(`/admin/experts/${current.id}/info`)
  }

  const onClose = () => {
    setCurrent(null)
    if (changed) {
      dispatch()
    }
  }

  if (!experts || !experts?.length) {
    return (
      <div className="spinner">
        <Empty description={<Text disabled>No matching experts found.</Text>} />
      </div>
    )
  }

  let extra = (
    <>
      <Thumbs expert={current} setChanged={setChanged} />
      <CardPin expert={current} setChanged={setChanged} />
      {user.admin && <Button onClick={editCurrent}>Edit</Button>}
    </>
  )

  return (
    <>
      {display == "list" ? (
        <ExpertTable experts={experts} setCurrent={setCurrent} dispatch={dispatch} />
      ) : (
        <div class="expert-grid-wrap">
          <div className="expert-grid">
            {experts.map((expert) => (
              <ExpertGridItem key={expert.id} expert={expert} setCurrent={setCurrent} dispatch={dispatch} />
            ))}
          </div>
        </div>
      )}
      <Drawer
        key={current?.id}
        title={fullName(current, "")}
        width={width}
        onClose={onClose}
        open={!!current}
        extra={[extra]}>
        <ExpertFields ex={current} readOnly />
      </Drawer>
    </>
  )
}
