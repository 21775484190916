import { useState } from "preact/hooks"
import { useOutletContext, useLoaderData, useNavigate, Form, useRevalidator } from "react-router-dom"
import Upload from "antd/es/upload"
import Button from "antd/es/button"
import Input from "antd/es/input"
import Space from "antd/es/space"
import Empty from "antd/es/empty"
import { InboxOutlined } from "@ant-design/icons"
import { ExpertCard } from "./expert-form"
import { gql } from "@apollo/client"
import client from "../client"
const { Dragger } = Upload
import Tag from "./tag"
import Select from "./select"
import { FileLink } from "./file-link"

function lastModToISO(lm) {
  if (!lm) {
    return null
  }
  return new Date(lm).toISOString()
}

function cleanFilename(s, display = true) {
  if (!s) {
    return null
  }
  s = s
    .split(/[^A-Za-z0-9._-]+/g)
    .filter((d) => d)
    .join(display ? " " : "_")

  if (!s.toLowerCase().endsWith(".pdf") && !display) {
    s += ".pdf"
  }
  return s
}

const gGet = gql`
  query ($id: UUID!) {
    filesGet(id: $id) {
      id
      expertID
      filename
      fileType
      mimetype
      size
      lastModified
      updated
      created
    }
  }
`
const gPost = gql`
  mutation FilePost($arg: FilePostParams!) {
    filePost(arg: $arg) {
      id
    }
  }
`
const gPut = gql`
  mutation FilePut($arg: FilePutParams!) {
    filePut(arg: $arg) {
      id
    }
  }
`
const gDelete = gql`
  mutation FileDelete($id: UUID!) {
    fileDelete(id: $id)
  }
`

function FilesTable({ expert, files, revalidator }) {
  const [current, setCurrent] = useState(null)
  const [fileType, setFileType] = useState(null)

  if (!files || !files.length) {
    return (
      <>
        <Empty description="No files" />
        <br />
      </>
    )
  }

  return (
    <Form
      method="post"
      onSubmit={() => {
        setCurrent(null)
        revalidator.revalidate()
        return true
      }}>
      <table className="edit-table">
        <colgroup>
          <col width="30" />
          <col width="*" />
          <col width="240" />
          <col width="100" />
          <col width="240" />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th>Filename</th>
            <th>Type</th>
            <th>Size</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, i) => (
            <tr key={"file-" + file.id}>
              <td>{i + 1 + "."}</td>
              <td>
                <FileLink expert={expert} file={file} edit={file.id === current} />
              </td>
              <td>
                {file.id !== current ? (
                  <Tag.FileType value={file.fileType} />
                ) : (
                  <Select.FileType name="fileType" defaultValue={file.fileType} onChange={setFileType} size="small" />
                )}
              </td>
              <td>
                <Tag.DataSize value={file.size} />
              </td>
              <td>
                {file.id !== current ? (
                  <Space.Compact size="small">
                    <Button onClick={() => setCurrent(file.id)}>Edit</Button>
                  </Space.Compact>
                ) : (
                  <Space.Compact size="small">
                    <Button size="small" onClick={() => setCurrent(null)}>
                      Cancel
                    </Button>
                    <input type="hidden" name="fileType" value={fileType} />
                    <input type="hidden" name="expertID" value={file.expertID} />
                    <input type="hidden" name="id" value={file.id} />
                    <input type="hidden" name="mimetype" value={file.mimetype} />
                    <input type="hidden" name="lastModified" value={file.lastModified} />
                    <Button size="small" htmlType="submit" name="verb" value="put">
                      Save
                    </Button>
                    <input type="hidden" name="id" value={file.id} />
                    <Button size="small" htmlType="submit" name="verb" value="delete">
                      Delete
                    </Button>
                  </Space.Compact>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Form>
  )
}

function FileUploader({ expert, addFile, revalidator }) {
  const navigate = useNavigate()
  const [fileList, setFileList] = useState([])

  const uploadFile = async (file) => {
    const { data, errors } = await client.mutate({
      mutation: gPost,
      variables: {
        arg: {
          expertID: expert.id,
          lastModified: lastModToISO(file.lastModified),
          upload: file,
        },
      },
    })
    if (errors) {
      throw errors
    }
    return data.fileUpload
  }

  const customRequest = ({ file, onSuccess, onError }) => {
    uploadFile(file)
      .then((data) => {
        onSuccess(data, file)
        revalidator.revalidate()
      })
      .catch((errors) => {
        const err = errors[0]
        onError(Error(err.path.join(".") + " " + err.message), errors, file)
      })
  }

  const allDone = ({ fileList }) => {
    setFileList([])
  }

  return (
    <Dragger
      name="importFile"
      accept={".pdf,application/pdf"}
      // action={preupFile}
      onChange={allDone}
      customRequest={customRequest}
      fileList={fileList}
      showUploadList={false}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag a PDF file to this area to add.</p>
    </Dragger>
  )
}

const ExpertFiles = () => {
  const revalidator = useRevalidator()
  const expert = useOutletContext()
  const files = useLoaderData() || []

  return (
    <ExpertCard activeTab="files" expert={expert}>
      <FilesTable files={files} expert={expert} revalidator={revalidator} />
      <FileUploader expert={expert} revalidator={revalidator} />
    </ExpertCard>
  )
}

ExpertFiles.loader = async ({ request, params }) => {
  const { errors, data } = await client.query({
    query: gGet,
    fetchPolicy: "network-only",
    variables: { id: params.id },
  })
  if (errors) {
    console.error(errors)
    throw new Response(JSON.stringify(errors, "", "   "), { status: 500 })
  }
  return data?.filesGet || []
}

ExpertFiles.action = async ({ request, params }) => {
  let variables = null
  let mutation = null
  let data = Object.fromEntries(await request.formData())
  const verb = data.verb || request.method.toLowerCase()
  switch (verb) {
    case "put":
      mutation = gPut
      variables = {
        arg: {
          id: data.id,
          fileType: data.fileType || null,
          displayName: data.displayName || null,
          filename: data.filename,
        },
      }
      break
    case "delete":
      mutation = gDelete
      variables = { id: data.id }
      break
    default:
      throw new Response(verb, { status: 405 })
  }
  const { errors } = await client.mutate({ mutation, variables })
  if (errors) {
    console.error(errors)
    throw new Response(JSON.stringify(errors, "", "   "), { status: 500 })
  }
  return null
}

export default ExpertFiles
