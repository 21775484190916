import { ApolloClient, InMemoryCache, gql, useQuery } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { createUploadLink } from "apollo-upload-client"

const SEARCH_GQL = gql`
  query ($arg: SearchParams!) {
    search(arg: $arg) {
      total
      current
      pageSize
      items
    }
  }
`

const STAR_POST_GQL = gql`
  mutation ($arg: StarPostParams!) {
    starPost(arg: $arg)
  }
`

export async function starPost(expertID, value) {
  return client
    .mutate({ mutation: STAR_POST_GQL, variables: { arg: { userID: "", expertID: expertID, value } } })
    .then((d) => d.starPost)
}

const PIN_POST_GQL = gql`
  mutation ($arg: PinPostParams!) {
    pinPost(arg: $arg)
  }
`

export async function pinPost(expertID, value) {
  return client
    .mutate({ mutation: PIN_POST_GQL, variables: { arg: { userID: "", expertID: expertID, value } } })
    .then((d) => d.pinPost)
}

const EXPERT_PUT_GQL = gql`
  mutation ($arg: ExpertParams!) {
    expertPut(arg: $arg) {
      id
      createdDate
      refreshDate
      title
      givenNames
      surname
      email
      address
      institution
      nationality
      residence
      gender
      dateOfBirth
      status
      companyName
      website
      profile
      idNum
      starred
      areas
      languages
      files {
        id
        filename
        fileType
        mimetype
        size
        updated
        created
      }
      links {
        id
        url
        updated
      }
      updated
      created
    }
  }
`

export async function expertPut({
  id,
  createdDate,
  refreshDate,
  title,
  givenNames,
  surname,
  email,
  address,
  institution,
  nationality,
  residence,
  gender,
  dateOfBirth,
  status,
  companyName,
  website,
  profile,
  idNum,
  areas,
  languages,
}) {
  return client
    .mutate({
      mutation: EXPERT_PUT_GQL,
      variables: {
        arg: {
          id: id || "",
          createdDate,
          refreshDate,
          title,
          givenNames,
          surname,
          email,
          address,
          institution,
          nationality,
          residence,
          gender,
          dateOfBirth,
          status,
          companyName,
          website,
          profile: profile || "",
          idNum: idNum || "",
          areas,
          languages,
        },
      },
    })
    .then((d) => d?.data?.expertPut)
}

const EXPERT_POST_GQL = gql`
  mutation ($arg: ExpertParams!) {
    expertPost(arg: $arg) {
      id
      createdDate
      refreshDate
      title
      givenNames
      surname
      email
      address
      institution
      nationality
      residence
      gender
      dateOfBirth
      status
      companyName
      website
      profile
      idNum
      starred
      areas
      languages
      files {
        id
        filename
        fileType
        mimetype
        size
        updated
        created
      }
      links {
        id
        url
        updated
      }
      updated
      created
    }
  }
`

export async function expertPost({
  id,
  createdDate,
  refreshDate,
  title,
  givenNames,
  surname,
  email,
  address,
  institution,
  nationality,
  residence,
  gender,
  dateOfBirth,
  status,
  companyName,
  website,
  profile,
  idNum,
  areas,
  languages,
}) {
  const { errors, data } = await client.mutate({
    mutation: EXPERT_POST_GQL,
    variables: {
      arg: {
        id: id || "",
        createdDate,
        refreshDate,
        title,
        givenNames,
        surname,
        email,
        address,
        institution,
        nationality,
        residence,
        gender,
        dateOfBirth,
        status,
        companyName,
        website,
        profile: profile || "",
        idNum: idNum || "",
        areas,
        languages,
      },
    },
  })
  if (errors) {
    const err = errors[0]
    throw new Error(err.path.join(".") + " " + err.message)
  }
  return data.expertPost
}
// query {

export function useSearch(arg) {
  return useQuery(SEARCH_GQL, { variables: { arg: arg }, fetchPolicy: "no-cache" })
}

const uploadLink = createUploadLink({
  uri: "/api/query",
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "Accept": "application/json",
      "x-rose-api": "1",
    },
  }
})

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
}

const client = new ApolloClient({
  uri: "/api/query",
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache({
    typePolicies: {
      ExpertView: {
        fields: {
          files: {
            merge(existing, incoming, { mergeObjects }) {
              return incoming
            },
          },
        },
      },
      Query: {
        fields: {
          filesGet: {
            merge(existing, incoming, { mergeObjects }) {
              return incoming
            },
          },
        },
      },
    },
  }),
  defaultOptions,
})

export default client

//###########################

const LOCALE_GQL = gql`
  query Locale {
    locale {
      titles {
        id
        name
      }
      tags {
        id
        name
      }
      statuses {
        id
        name
      }
      genders {
        id
        name
      }
      areas {
        id
        name
      }
      languages {
        id
        name
        official
      }
      fileTypes {
        id
        name
      }
      regions {
        id
        name
      }
      countries {
        id
        name
        regionID
      }
      limits {
        size
        types
        exts
      }
    }
  }
`

export async function localeGet() {
  return client
    .query({
      query: LOCALE_GQL,
      fetchPolicy: "cache-first",
      variables: {},
    })
    .then((d) => d?.data)
}

export async function selfGet() {
  const response = await fetch("/api/self", {
    method: "GET",
    mode: "same-origin",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Accept": "application/json",
      "x-rose-api": "1",
    },
    redirect: "error",
    referrerPolicy: "no-referrer",
  })
  return response.json()
}

const USER_PUT_GQL = gql`
  mutation ($arg: UserPutParams!) {
    userPut(arg: $arg) {
      id
      subject
      admin
      banned
      lastLogin
      updated
      created
    }
  }
`

export async function userPut({ id, admin, banned }) {
  return client
    .mutate({
      mutation: USER_PUT_GQL,
      variables: {
        arg: {
          id,
          admin,
          banned,
        },
      },
    })
    .then((d) => d?.data?.userPut)
}

//##############################################################################
