import { useLocale } from "../contexts"

export function FileLink({ expert, file, edit }) {
  const locale = useLocale()
  const typeName = (locale.lookup.fileTypes.get(file.fileType) || "Unknown").replaceAll(" ", "")

  const s = file.created
  const parts = [
    expert.idNum,
    expert.surname.toUpperCase(),
    expert.givenNames,
    s.substr(0, 4) + s.substr(5, 2) + s.substr(8, 2),
    "TypeName",
  ]
  const value = parts.filter((d) => d).join("_") + ".pdf"
  const filename = value.replaceAll("TypeName", typeName)

  if (edit !== false) {
    return (
      <>
        {edit === true && <input type="hidden" name="filename" value={value} />}
        <span className="file-name">{filename}</span>
      </>
    )
  }
  return (
    <a className="file-name" href={`/files/${file.id}/${filename}`} rel="noopener noreferrer" target="_blank">
      {filename}
    </a>
  )
}
