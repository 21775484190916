import { useState } from "preact/hooks"
import { useOutletContext, useLoaderData, useNavigate, Form, redirect, useSubmit } from "react-router-dom"
import { gql } from "@apollo/client"
import client from "../client"
import { useLocale } from "../contexts"
import Card from "antd/es/card"
import Breadcrumb from "antd/es/breadcrumb"
import Table from "antd/es/table"
import Button from "antd/es/button"
import Input from "antd/es/input"
import Space from "antd/es/space"
import Empty from "antd/es/empty"
import Checkbox from "antd/es/checkbox"
import Tag from "./tag"
import Select from "./select"

export const optionTypes = [
  {
    kind: "areas",
    name: "Area of Expertise",
    prefix: "area",
  },
  {
    kind: "fileTypes",
    name: "File Type",
    prefix: "fileType",
  },
  {
    kind: "languages",
    name: "Language",
    prefix: "language",
  },
  {
    kind: "statuses",
    name: "Status",
    prefix: "status",
  },
  {
    kind: "titles",
    name: "Title",
    prefix: "title",
  },
  {
    kind: "genders",
    name: "Gender",
    prefix: "gender",
  },
  {
    kind: "countries",
    name: "Country",
    prefix: "country",
  },
  {
    kind: "regions",
    name: "Region",
    prefix: "region",
  },
]

function SelectRegion({ defaultValue, disabled }) {
  const [value, setValue] = useState(defaultValue)
  return (
    <>
      <input type="hidden" name={disabled ? null : "regionID"} value={value} />
      <Select.Region defaultValue={value} disabled={disabled} onChange={setValue} />
    </>
  )
}

function OfficialCheck(props) {
  const { defaultChecked, ...other } = props
  const [checked, setChecked] = useState(defaultChecked)
  return (
    <>
      <input type="hidden" name={other.disabled ? null : "official"} value={checked} />
      <Checkbox checked={checked} onChange={setChecked} {...other} />
    </>
  )
}

export function makeOptionsTable(kind, name, prefix) {
  const OptionsTable = () => {
    const options = useLoaderData() || []
    const [current, setCurrent] = useState(null)

    if (!options || !options.length) {
      return (
        <>
          <Empty description={`No '${kind}' options`} />
        </>
      )
    }

    const disabled = current !== null

    return (
      <div className="options-table">
        <Breadcrumb items={[{ title: "Admin" }, { title: "Options" }, { title: name }]} />
        <Card title={false} bordered={true} extra={false}>
          <Form method="put">
            <table className="edit-table">
              <colgroup>
                <col width="40" />
                <col width="120" />
                <col span={kind == "countries" || kind == "languages" ? 2 : 1} width="*" />
                <col width="180" />
              </colgroup>
              <thead>
                <tr>
                  <th></th>
                  <th>ID</th>
                  <th>Name</th>
                  {kind == "countries" ? <th>Region</th> : null}
                  {kind == "languages" ? <th>Official</th> : null}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {options.map((option, i) => (
                  <tr key={option.id}>
                    <td>{i + 1 + "."}</td>
                    <td>{option.id}</td>
                    <td>
                      {option.id !== current ? (
                        <span>{option?.name}</span>
                      ) : (
                        <Input name="name" defaultValue={option?.name} autoFocus />
                      )}
                    </td>
                    {kind == "countries" ? (
                      <td>
                        {option.id !== current ? (
                          <span>{option?.regionID}</span>
                        ) : (
                          <SelectRegion defaultValue={option?.regionID} />
                        )}
                      </td>
                    ) : null}
                    {kind == "languages" ? (
                      <td>
                        <OfficialCheck defaultChecked={option?.official || false} disabled={option.id !== current} />
                      </td>
                    ) : null}
                    <td>
                      {option.id !== current ? (
                        <Space.Compact size="small">
                          <Button onClick={() => setCurrent(option?.id)}>Edit</Button>
                        </Space.Compact>
                      ) : (
                        <Space.Compact size="small">
                          <Button size="small" onClick={() => setCurrent(null)}>
                            Cancel
                          </Button>
                          <input type="hidden" name="id" value={option?.id} />
                          <Button size="small" htmlType="submit" name="verb" value="put">
                            Save
                          </Button>
                          <Button size="small" htmlType="submit" name="verb" value="delete">
                            Delete
                          </Button>
                        </Space.Compact>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Form>
          <Form method="post">
            <table className="edit-table">
              <colgroup>
                <col width="40" />
                <col width="120" />
                <col span={kind == "countries" || kind == "languages" ? 2 : 1} width="*" />
                <col width="180" />
              </colgroup>
              <tbody>
                <tr>
                  <td></td>
                  <td>
                    <Input name="id" disabled={disabled} autoFocus={!disabled} />
                  </td>
                  <td>
                    <Input name="name" disabled={disabled} autoFocus={!disabled} />
                  </td>
                  {kind == "countries" ? (
                    <td>
                      <SelectRegion disabled={disabled} />
                    </td>
                  ) : null}
                  {kind == "languages" ? (
                    <td>
                      <OfficialCheck defaultChecked={false} disabled={disabled} />
                    </td>
                  ) : null}
                  <td>
                    <Space.Compact size="small">
                      <Button size="small" htmlType="submit" name="verb" value="post" disabled={disabled}>
                        Add
                      </Button>
                    </Space.Compact>
                  </td>
                </tr>
              </tbody>
            </table>
          </Form>
        </Card>
      </div>
    )
  }
  let extraCols = ""
  if (kind == "countries") {
    extraCols = "regionID"
  } else if (kind == "languages") {
    extraCols = "official"
  }
  const uprefix = prefix.charAt(0).toUpperCase() + prefix.slice(1)
  const gGet = gql`query ${uprefix}Get { ${kind} { id name ${extraCols} } }`
  const gPost = gql`mutation($arg: ${uprefix}PostParams!) { ${prefix}Post(arg: $arg) { id } }`
  const gPut = gql`mutation($arg: ${uprefix}PutParams!) { ${prefix}Put(arg: $arg) { id } }`
  const gDelete = gql`mutation($id: ID!) { ${prefix}Delete(id: $id) }`

  const loader = async ({ request, params }) => {
    const { errors, data } = await client.query({ query: gGet, fetchPolicy: "network-only", variables: {} })
    if (errors) {
      console.error(errors)
      throw new Response(JSON.stringify(errors, "", "   "), { status: 500 })
    }
    if (!data || !data[kind]) {
      return []
    }
    return data[kind]
  }

  const action = async ({ request, params }) => {
    let variables = {}
    let mutation = null

    let data = Object.fromEntries(await request.formData())
    let arg = {
      id: data.id,
      name: data.name,
    }
    if (data.regionID) {
      arg.regionID = data.regionID
    }
    if (data.official || data.official === false) {
      arg.official = data.official === "true"
    }

    const verb = data.verb || request.method.toLowerCase()
    switch (verb) {
      case "post":
        mutation = gPost
        variables = { arg }
        break
      case "put":
        mutation = gPut
        variables = { arg }
        break
      case "delete":
        mutation = gDelete
        variables = { id: data.id }
        break
      default:
        throw new Response(verb, { status: 405 })
    }
    const { errors } = await client.mutate({ mutation, variables })
    if (errors) {
      console.error(errors)
      throw new Response(JSON.stringify(errors, "", "   "), { status: 500 })
    }
    return null
  }

  return {
    id: kind,
    path: kind,
    element: <OptionsTable />,
    loader: loader,
    action: action,
  }
}

export const optionRoutes = optionTypes.map(({ kind, name, prefix }) => {
  return makeOptionsTable(kind, name, prefix)
})
