import { useState } from "preact/hooks"
import { useSubmit, useOutletContext, useLoaderData, useNavigate, Form } from "react-router-dom"
import Input from "antd/es/input"
import Space from "antd/es/space"
import Button from "antd/es/button"
import Empty from "antd/es/empty"
import { ExpertCard } from "./expert-form"
import { gql } from "@apollo/client"
import client from "../client"

function LinksTable({ links, current, setCurrent }) {
  if (!links || !links.length) {
    return (
      <>
        <Empty description="No links" />
        <br />
      </>
    )
  }

  return (
    <Form
      method="put"
      onSubmit={() => {
        setCurrent(null)
        return true
      }}>
      <table className="edit-table">
        <colgroup>
          <col width="40" />
          <col span="1" width="*" />
          <col width="180" />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th>URL</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {links.map((link, i) => (
            <tr key={link.id}>
              <td>{i + 1 + "."}</td>
              <td>
                {link.id !== current ? (
                  <a href={link.url} rel="noopener noreferrer" target="_blank">
                    {link.url}
                  </a>
                ) : (
                  <Input name="url" defaultValue={link.url} autoFocus />
                )}
              </td>
              <td>
                {link.id !== current ? (
                  <Space.Compact size="small">
                    <Button onClick={() => setCurrent(link.id)}>Edit</Button>
                  </Space.Compact>
                ) : (
                  <Space.Compact size="small">
                    <Button size="small" onClick={() => setCurrent(null)}>
                      Cancel
                    </Button>
                    <input type="hidden" name="expertID" value={link.expertID} />
                    <input type="hidden" name="id" value={link.id} />
                    <Button size="small" htmlType="submit" name="verb" value="put">
                      Save
                    </Button>
                    <Button size="small" htmlType="submit" name="verb" value="delete">
                      Delete
                    </Button>
                  </Space.Compact>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Form>
  )
}

function LinksAdd({ expert, current, setCurrent }) {
  const disabled = current !== null

  return (
    <Form method="post">
      <table className="edit-table">
        <colgroup>
          <col width="40" />
          <col span="1" width="*" />
          <col width="180" />
        </colgroup>
        <tbody>
          <tr>
            <td>
              <input name="id" type="hidden" value="" />
              <input type="hidden" name="expertID" value={expert.id} />
            </td>
            <td>
              <Input name="url" disabled={disabled} autoFocus={!disabled} />
            </td>
            <td>
              <Space.Compact size="small">
                <Button size="small" htmlType="submit" name="verb" value="post" disabled={disabled}>
                  Add
                </Button>
              </Space.Compact>
            </td>
          </tr>
        </tbody>
      </table>
    </Form>
  )
}

const gGet = gql`
  query LinksGet($id: UUID!) {
    linksGet(id: $id) {
      id
      expertID
      url
      updated
    }
  }
`
const gPost = gql`
  mutation LinkPost($arg: LinkPostParams!) {
    linkPost(arg: $arg) {
      id
    }
  }
`
const gPut = gql`
  mutation LinkPut($arg: LinkPutParams!) {
    linkPut(arg: $arg) {
      id
    }
  }
`
const gDelete = gql`
  mutation LinkDelete($id: UUID!) {
    linkDelete(id: $id)
  }
`

const ExpertLinks = () => {
  const expert = useOutletContext() || {}
  const links = useLoaderData() || []

  const [current, setCurrent] = useState(null)

  return (
    <ExpertCard activeTab="links" expert={expert}>
      <LinksTable links={links} current={current} setCurrent={setCurrent} />
      <LinksAdd expert={expert} current={current} setCurrent={setCurrent} />
    </ExpertCard>
  )
}

ExpertLinks.loader = async ({ request, params }) => {
  const { errors, data } = await client.query({
    query: gGet,
    fetchPolicy: "network-only",
    variables: { id: params.id },
  })
  if (errors) {
    console.error(errors)
    throw new Response(JSON.stringify(errors, "", "   "), { status: 500 })
  }
  return data?.linksGet || []
}

ExpertLinks.action = async ({ request, params }) => {
  let variables = null
  let mutation = null
  let data = Object.fromEntries(await request.formData())
  const verb = data.verb || request.method.toLowerCase()
  switch (verb) {
    case "post":
      mutation = gPost
      variables = {
        arg: {
          expertID: data.expertID,
          url: data.url,
        },
      }
      break
    case "put":
      mutation = gPut
      variables = {
        arg: {
          id: data.id,
          expertID: data.expertID,
          url: data.url,
        },
      }
      break
    case "delete":
      mutation = gDelete
      variables = { id: data.id }
      break
    default:
      throw new Response(verb, { status: 405 })
  }
  const { errors } = await client.mutate({ mutation, variables })
  if (errors) {
    console.error(errors)
    throw new Response(JSON.stringify(errors, "", "   "), { status: 500 })
  }
  return null
}

export default ExpertLinks
