import Input from "antd/es/input"
import { SearchOutlined, CloseOutlined, LoadingOutlined } from "@ant-design/icons"

export default function SearchInput({ loading, ...props }) {
  const prefix = loading ? <LoadingOutlined spin /> : <SearchOutlined />

  return (
    <Input placeholder="Search" prefix={<SearchOutlined />} allowClear={{ clearIcon: <CloseOutlined /> }} {...props} />
  )
}
