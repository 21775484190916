import Divider from "antd/es/divider"
import Table from "antd/es/table"
import Tag from "./tag"

function fullName(item, defv = "") {
  if (!item) {
    return defv
  }
  return [(item.surname || "").toUpperCase(), item.givenNames].filter((d) => d).join(", ")
}

function rating(expert) {
  let down = ~~Math.floor((expert.numRatings - expert.netRating) / 2)
  let up = expert.numRatings - down

  if (!expert.numRatings) {
    return ""
  }
  return `${up.toLocaleString()} of ${expert.numRatings.toLocaleString()}`
}

const columns = [
  {
    title: "From",
    render: (_, d) => {
      return <Tag.Flag value={d.residence || d.nationality} />
    },
    width: "60px",
    align: "right",
  },
  {
    title: "Name",
    dataIndex: "name",
    render: (_, d) => {
      return <span>{fullName(d)}</span>
    },
    width: "180px",
  },
  {
    title: "Rec.",
    render: (_, d) => {
      return <span>{rating(d)}</span>
    },
    width: "80px",
  },
  {
    title: "Services",
    render: (_, d) => {
      return <Tag.Areas values={d.areas} />
    },
  },
  {
    title: "Languages",
    render: (_, d) => {
      return <Tag.Languages values={d.languages} />
    },
  },
]

const ExpertTable = ({ loading, display, experts, dispatch, setCurrent }) => {
  const data = experts.map((ex) => {
    return {
      ...ex,
      key: "expert-row-" + ex.id,
      name: fullName(ex),
    }
  })
  return (
    <div class="expert-table-wrap">
      <Table
        className="expert-table"
        columns={columns}
        dataSource={data}
        size="small"
        bordered={false}
        pagination={false}
        sticky={true}
        scroll={true}
        onRow={(d) => ({
          onClick: () => {
            setCurrent(d)
          },
        })}
      />
    </div>
  )
}
export default ExpertTable
