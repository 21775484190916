import { useNavigate, useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import Breadcrumb from "antd/es/breadcrumb"
import Button from "antd/es/button"
import Card from "antd/es/card"
import Space from "antd/es/space"
import Typography from "antd/es/typography"
const { Text } = Typography

function fullName(item, defv = "") {
  if (!item) {
    return defv
  }
  return [(item.surname || "").toUpperCase(), item.givenNames].filter((d) => d).join(", ")
}

export default function AdminItem() {
  const navigate = useNavigate()

  let { id } = useParams()

  const { loading, error, data, refetch } = useQuery(EXPERT_GQL, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  })

  if (loading || error || !data) {
    return <></>
  }

  const current = data.expertGet

  const onClose = () => {
    navigate("/admin")
  }

  return (
    <div className="admin-item">
      <Breadcrumb
        items={[
          { title: "Admin" },
          { title: "Experts", href: "/admin/experts" },
          { title: <Text strong>{fullName(current)}</Text> },
        ]}
      />
      <Card
        title={fullName(current)}
        bordered={true}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onClose} type="primary" xdisabled>
              Save
            </Button>
          </Space>
        }>
        <ExpertForm ex={current} />
      </Card>
    </div>
  )
}
