import { useMemo } from "preact/hooks"
import { useLocale } from "../contexts"
import { DateTime } from "luxon"
import prettyBytes from "pretty-bytes"

const lc = (s) => {
  if (typeof s !== "string") {
    return ""
  }
  return s.toLowerCase()
}

const getLocalTZ = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch (err) {
    return "UTC"
  }
}

function tag(kind) {
  return function (props) {
    const { value, ...other } = props
    const locale = useLocale()

    const label = useMemo(() => {
      return locale.lookup[kind]?.get(value) || value
    }, [locale, kind, value])

    return (
      <data value={value} title={label} {...other}>
        {label}
      </data>
    )
  }
}

function tags(kind) {
  const InnerTag = tag(kind)
  return function (props) {
    const { values, ...other } = props
    return (
      <>
        {values?.map((d) => (
          <InnerTag value={d} {...other} />
        ))}
      </>
    )
  }
}

const Tag = {
  Title: tag("titles"),
  Tag: tag("tags"),
  Status: tag("statuses"),
  Gender: tag("genders"),
  Area: tag("areas"),
  Language: tag("languages"),
  FileType: tag("fileTypes"),
  Region: tag("regions"),
  Country: tag("countries"),
  CountryRegion: tag("countryRegions"),
  CountryShortRegion: tag("countryShortRegions"),
  Areas: tags("areas"),
  Languages: tags("languages"),
  Tags: tags("tags"),
  Flag: ({ value }) => {
    const locale = useLocale()
    const country = useMemo(() => {
      return locale.lookup["countries"]?.get(value) || value
    }, [locale, value])
    return <img class="flag icon" src={"/flags/" + (lc(value) || "xx") + ".png"} title={country} />
  },
  Num: ({ value }) => {
    if (!value && value !== 0) {
      return <></>
    }
    const n = Number(value)
    return <data value={value}>{n.toLocaleString()}</data>
  },
  DataSize: ({ value }) => {
    if (!value && value !== 0) {
      return <></>
    }
    const n = Number(value)
    return <data value={value}>{prettyBytes(value)}</data>
  },
  Date: ({ value }) => {
    if (!value) {
      return <></>
    }

    var iso = DateTime.fromISO(value)
    iso.setZone(getLocalTZ())
    return <data value={value}>{iso.toFormat("DD")}</data>
  },
  DateTime: ({ value }) => {
    if (!value) {
      return <></>
    }

    var iso = DateTime.fromISO(value)
    iso.setZone(getLocalTZ())
    return (
      <data value={value}>
        {iso.toFormat("DD")}&emsp;{iso.toFormat("tt")}
      </data>
    )
  },
}

export default Tag
