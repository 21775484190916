import { useState } from "preact/hooks"
import { useNavigate, useParams, Link, useOutletContext } from "react-router-dom"
import { useForm, Controller } from "react-hook-form"
import { useQuery, gql } from "@apollo/client"
import Breadcrumb from "antd/es/breadcrumb"
import Button from "antd/es/button"
import Card from "antd/es/card"
import Input from "antd/es/input"
import Space from "antd/es/space"
import Typography from "antd/es/typography"
import Upload from "antd/es/upload"
import { LeftOutlined, InboxOutlined } from "@ant-design/icons"
import client, { expertPut, expertPost } from "../client"
import Select from "./select"
const { Text } = Typography
const { Dragger } = Upload

function fullName(item, defv = "") {
  if (!item) {
    return defv
  }
  return [(item.surname || "").toUpperCase(), item.givenNames].filter((d) => d).join(", ")
}

const YMD_RX = /^$|^[12][0-9]{3}-[01][0-9]-[0123][0-9]$/
const URL_RX = /^https?:\/\/(-\.)?([^\s\/?\.#-]+\.?)+(\/[^\s]*)?$/i
const EMAIL_RX =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i
const gGet = gql`
  query ($id: UUID!) {
    expertGet(id: $id) {
      id
      createdDate
      refreshDate
      title
      givenNames
      surname
      email
      address
      institution
      nationality
      residence
      gender
      dateOfBirth
      status
      companyName
      website
      profile
      idNum
      starred
      areas
      languages
      files {
        id
        filename
        fileType
        mimetype
        size
        updated
      }
      links {
        id
        url
        updated
      }
      updated
      created
    }
  }
`

export const expertLoader = async ({ request, params }) => {
  const { errors, data } = await client.query({
    query: gGet,
    fetchPolicy: "network-only",
    variables: { id: params.id },
  })
  if (errors) {
    console.error(errors)
    throw new Response(JSON.stringify(errors, "", "   "), { status: 500 })
  }
  return data?.expertGet
}

const EXPERT_GQL = gql`
  query ($id: UUID!) {
    expertGet(id: $id) {
      id
      createdDate
      refreshDate
      title
      givenNames
      surname
      email
      address
      institution
      nationality
      residence
      gender
      dateOfBirth
      status
      companyName
      website
      profile
      idNum
      starred
      areas
      languages
      files {
        id
        filename
        fileType
        mimetype
        size
        updated
      }
      links {
        id
        url
        updated
      }
      updated
      created
    }
  }
`

function HInput({ control, errors, name, rules }) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { invalid, isTouched, isDirty, error }, formState }) => {
        return <Input status={error ? "error" : undefined} {...field} />
      }}
    />
  )
}

function delay(n) {
  return new Promise(function (resolve, reject) {
    window.setTimeout(() => {
      resolve()
    }, n)
  })
}

export function ExpertCard({ activeTab, expert, isDirty, controls, children }) {
  const navigate = useNavigate()
  const isNew = !expert

  const tabs = [
    {
      key: "info",
      tab: "Info",
    },
    {
      key: "links",
      tab: "Links",
      disabled: !!(isNew || isDirty),
    },
    {
      key: "files",
      tab: "Files",
      disabled: !!(isNew || isDirty),
    },
    {
      key: "notes",
      tab: "Notes",
      disabled: !!(isNew || isDirty),
    },
  ]

  const extra = {
    left: <Button type="text" icon={<LeftOutlined />} onClick={() => navigate(`/admin/experts`)} size="large" />,
    right: controls,
  }

  let onTabChange = false
  if (!isNew) {
    onTabChange = (key) => {
      navigate("/admin/experts/" + expert.id + "/" + key, { replace: true })
    }
  }

  return (
    <div className="admin-item">
      <Breadcrumb
        items={[
          { title: "Admin" },
          { title: "Experts", href: "/admin/experts" },
          { title: <Text strong>{isNew ? "New Expert" : fullName(expert)}</Text> },
        ]}
      />
      <Card
        title={false}
        bordered={true}
        extra={false}
        tabBarExtraContent={extra}
        tabList={tabs}
        activeTabKey={activeTab}
        onTabChange={onTabChange}>
        {children}
      </Card>
    </div>
  )
}

export function ExpertInfo() {
  const navigate = useNavigate()
  const onClose = () => {
    navigate("/admin/experts")
  }
  let expert = useOutletContext() || {}

  const today = new Date().toISOString().substr(0, 10)
  const current = {
    address: "",
    areas: [],
    companyName: "",
    createdDate: today,
    dateOfBirth: "",
    email: "",
    gender: "",
    givenNames: "",
    id: "",
    institution: "",
    languages: [],
    nationality: "",
    refreshDate: today,
    residence: "",
    status: "ACTIVE",
    surname: "",
    title: "",
    website: "",
    ...(expert || {}),
  }

  const {
    reset,
    register,
    handleSubmit,
    watch,
    control,
    formState: { isDirty, dirtyFields, touchedFields, errors, isSubmitting },
  } = useForm({
    defaultValues: current,
  })

  const onSubmit = async (data) => {
    if (!data.id) {
      const result = await expertPost(data)
      navigate("/admin/experts/" + result.id + "/info")
    } else {
      const result = await expertPut(data)
      reset(result)
    }
  }

  const onError = (errors, e) => {
    console.error(errors)
  }
  const canSubmit = isDirty

  const controls = (
    <Space>
      <div></div>
      <Button onClick={onClose}>Back</Button>
      <Button
        onClick={() => {
          reset(expert)
        }}>
        Reset
      </Button>
      <Button type="primary" loading={isSubmitting} onClick={handleSubmit(onSubmit, onError)} disabled={!canSubmit}>
        Save
      </Button>
    </Space>
  )

  return (
    <ExpertCard activeTab="info" expert={expert} controls={controls} isDirty={isDirty}>
      <div className="grid-form">
        <div class="field">
          <label>Title</label>
          {/*<HInput control={control} name="title" />*/}
          <Controller name="title" control={control} render={({ field }) => <Select.Title {...field} />} />
        </div>
        <div class="field c5">
          <label>Given Names</label>
          <HInput control={control} name="givenNames" />
        </div>
        <div class="field c4">
          <label>Surname</label>
          <HInput control={control} name="surname" rules={{ required: true }} />
        </div>
        <div class="field c2">
          <label>Status</label>
          <Controller name="status" control={control} render={({ field }) => <Select.Status {...field} />} />
        </div>
        <div class="field c6">
          <label>Services</label>
          <Controller name="areas" control={control} render={({ field }) => <Select.Areas {...field} />} />
        </div>
        <div class="field c6">
          <label>Languages</label>
          <Controller name="languages" control={control} render={({ field }) => <Select.Languages {...field} />} />
        </div>
        <div class="field c3">
          <label>Gender</label>
          <Controller name="gender" control={control} render={({ field }) => <Select.Gender {...field} />} />
        </div>
        <div class="field c3">
          <label>Date of Birth</label>
          <HInput control={control} name="dateOfBirth" rules={{ pattern: YMD_RX }} />
        </div>
        <div class="field c3">
          <label>Created Date</label>
          <HInput control={control} name="createdDate" rules={{ required: true, pattern: YMD_RX }} />
        </div>
        <div class="field c3">
          <label>Refresh Date</label>
          <HInput control={control} name="refreshDate" rules={{ required: true, pattern: YMD_RX }} />
        </div>
        <div class="field c3">
          <label>Residence</label>
          <Controller name="residence" control={control} render={({ field }) => <Select.Country {...field} />} />
        </div>
        <div class="field c9">
          <label>Email</label>
          <HInput control={control} name="email" rules={{ pattern: EMAIL_RX }} />
        </div>
        <div class="field c3">
          <label>Nationality</label>
          <Controller name="nationality" control={control} render={({ field }) => <Select.Country {...field} />} />
        </div>
        <div class="field c9">
          <label>Institution</label>
          <HInput control={control} name="institution" />
        </div>
        <div class="field c4 r2">
          <label>Address</label>
          <Controller name="address" control={control} render={({ field }) => <Input.TextArea {...field} />} />
        </div>
        <div class="field c8">
          <label>Company</label>
          <HInput control={control} name="companyName" />
        </div>
        <div class="field c8">
          <label>Website</label>
          <HInput control={control} name="website" rules={{ pattern: URL_RX }} />
        </div>
      </div>
    </ExpertCard>
  )
}

export default function ExpertItem() {
  const navigate = useNavigate()

  let { id, activeTab } = useParams()

  const { loading, error, data, refetch } = useQuery(EXPERT_GQL, {
    variables: {
      id: id || "",
    },
  })

  if (error) {
    console.error(error)
  }

  if (loading || !data) {
    return <></>
  }

  const expert = data.expertGet

  if (activeTab == "info") {
    return <ExpertInfo expert={expert} />
  } else if (activeTab == "links") {
    return <ExpertLinks expert={expert} />
  } else if (activeTab == "files") {
    return <ExpertFiles expert={expert} />
  }

  const expertTabs = [
    {
      key: "info",
      tab: "Info",
    },
    {
      key: "links",
      tab: "Links",
    },
    {
      key: "files",
      tab: "Files",
    },
  ]

  const onClose = () => {
    navigate("/admin/")
  }

  const content = {
    info: <ExpertForm ex={current} />,
    links: <p>links</p>,
    files: <p>files</p>,
  }

  const controls = {
    info: (
      <Space>
        <Button onClick={() => navigate("/admin/experts")}>Back</Button>
        <Button onClick={onClose} type="primary" xdisabled>
          Save
        </Button>
      </Space>
    ),
  }

  const extraContent = {
    left: <Button type="text" icon={<LeftOutlined />} onClick={() => navigate(-1)} size="large" />,
    right: controls[activeTab],
  }

  return (
    <div className="admin-item">
      <Breadcrumb
        items={[
          { title: "Admin" },
          { title: "Experts", href: "/admin/experts" },
          { title: <Text strong>{fullName(current)}</Text> },
        ]}
      />
      <Card
        title={false}
        bordered={true}
        extra={false}
        tabBarExtraContent={extraContent}
        tabList={expertTabs}
        activeTabKey={activeTab}
        onTabChange={(key) => {
          navigate("../" + current.id + "/" + key, { replace: true })
        }}>
        {content[activeTab]}
      </Card>
    </div>
  )
}

export function ExpertAdd() {
  return <ExpertInfo expert={null} />
}
